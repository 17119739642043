<section class="about">
  <Header />
  <div class="flex justify-content-center mt-3 xl:mt-5">
    <div class="dynwid px-2">
      <h2 class="font-semibold mt-5 mb-2">
        {{ headline }}
      </h2>
    </div>
  </div>
  <div class="flex gray justify-content-center mt-1 px-2 xl:pt-5 xl:px-5 pt-2">
    <Card class="mt-0 mb-5 border-1 border-gray-300 dynwid">
      <template #content>
        <div class="px-2 -mt-4"
             v-html="body">
        </div>
      </template>
    </Card>

  </div>
  <Footer />
</section>