<section class="flight-search">
  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="flex flex-wrap justify-content-between align-items-start flight-search-form mb-3 xl:mb-5">
    <div class="airport-picker block xl:flex xl:flex-wrap">
      <div class="airport-input-wrapper">
        <AutoComplete @item-select="moveDepartureInputCursorToBeginning" forceSelection class="airport-input airport-input-departure" @click="clearDeparture" v-model="v$.departure.$model" :suggestions="fetchedAirports" @complete="searchAirports($event)" field="label" :placeholder="departureAirportPlaceholder">
          <template #item="slotProps">
            <div class="flex"><img :src="airplaneIcon" class="autocomplete-icon">
              <div>{{ slotProps.item.label }}</div>
            </div>
          </template>
        </AutoComplete>
        <div :class="{'block': (v$.departure.$invalid && submitted) || v$.departure.$pending.$response}" v-if="(v$.departure.$invalid && submitted) || v$.departure.$pending.$response" class="p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
          {{ v$.departure.required.$message.replace('Value is required', departingAirportError) }}
        </div>
      </div>
      <Button class="p-button-rounded swap-button" icon="pi pi-sort-alt" @click="swapAirports">
      </Button>
      <div class="airport-input-wrapper">
        <AutoComplete @item-select="moveArrivalInputCursorToBeginning" forceSelection class="airport-input airport-input-arrival" @click="clearArrival" v-model="v$.arrival.$model" :suggestions="fetchedAirports" @complete="searchAirports($event)" field="label" :placeholder="returnAirportPlaceholder">
          <template #item="slotProps">
            <div class="flex"><img :src="airplaneIcon" class="autocomplete-icon">
              <div>{{ slotProps.item.label }}</div>
            </div>
          </template>
        </AutoComplete>
        <div :class="{'block': (v$.arrival.$invalid && submitted) || v$.arrival.$pending.$response}" v-if="(v$.arrival.$invalid && submitted) || v$.arrival.$pending.$response" class="p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
          {{ v$.departure.required.$message.replace('Value is required', returnAirportError) }}
        </div>
      </div>
    </div>
    <div class="calendars">
      <div class="date-buttons">
        <Button @click="toggleCalendarOverlay('departure', $event)" icon="pi pi-calendar" iconPos="right" :label="departureCalendarLabel" :class="{'text-green-500': !departureCalendarLabelIsDateCheck}" class="departure-calendar-button p-button-outlined calendar-button p-button-sm overflow-hidden text-overflow-ellipsis"></Button>
        <Button @click="toggleCalendarOverlay('return', $event)" icon="pi pi-calendar" iconPos="right" :label="returnCalendarLabel" :class="{'text-green-500': !returnCalendarLabelIsDateCheck}" class="p-button-outlined calendar-button p-button-sm return-calendar-button" :disabled="isOneWay"></Button>
        <div class="p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left" :class="{'block': (v$.dates.$invalid && submitted) || v$.dates.$pending.$response}" v-if="(v$.dates.$invalid && submitted) || v$.dates.$pending.$response">
          <!-- {{ v$.departure.required.$message.replace('Value is required', `${dateError} ${v$.dates.$model}` -->
          {{ v$.departure.required.$message.replace('Value is required', `${dateError}`) }}
        </div>
      </div>
    </div>
    <div class="passenger-picker grid">
      <Button :label="discretePassengerLabel" @click="togglePassengerPickerOverlay" class="p-button-outlined p-button-secondary p-button-sm passenger-button"></Button>
    </div>
    <div class="submit-button field">
      <div class="grid">
        <div class="col-12 p-0">
          <Button type="submit" :label="submitLabel" class="p-button-sm flex justify-content-center submit-button">
            <div class="col-12 text-white">{{ submitLabel }}</div>
          </Button>
        </div>
      </div>
    </div>
  </form>

  <!-- Calendar Sidebar -->
  <Sidebar class="calendar-sidebar" v-model:visible="showCalendarSidebar" position="full" :showCloseIcon="false">

    <div class="grid fixed z-5 bg-white left-0">
      <div class="col-12 mt-2 flex justify-content-between px-3">
        <div>
          <h2 class="mt-0 mb-0">{{ dateSelectionLabel }}</h2>
        </div>
        <div>
          <i class="pi pi-times mt-2" @click="() => showCalendarSidebar = false"></i>
        </div>
      </div>
      <div class="col-12">
        <div class="date-buttons flex justify-content-center p-buttonset mt-2">
          <Button icon="pi pi-calendar" iconPos="right" :label="departureCalendarLabel" class="departure-calendar-button p-button-outlined calendar-button p-button-sm"></Button>
          <Button icon="pi pi-calendar" iconPos="right" :label="returnCalendarLabel" class="p-button-outlined calendar-button p-button-sm" :disabled="oneWay"></Button>
        </div>
      </div>
      <div class="col-12 px-3">
        <table class="w-12 p-0 bs-0">
          <thead class="w-12 p-0">
            <th class="divide-in-seven" v-for="day in calendarWeekdays">
              {{ day }}
            </th>
          </thead>
        </table>
      </div>
      <div class="upper-gradient"></div>

    </div>

    <!-- dummy grid below -->
    <div class="grid z-4">
      <div class="col-12 mt-2 flex justify-content-between">
        <div>
          <h2 class="mt-0 mb-0">{{ dateSelectionLabel }}</h2>
        </div>
        <div>
          <i class="pi pi-times mt-2"></i>
        </div>
      </div>
      <div class="col-12">
        <div class="date-buttons flex justify-content-center p-buttonset mt-2">
          <Button icon="pi pi-calendar" iconPos="right" :label="departureCalendarLabel" class="departure-calendar-button p-button-outlined calendar-button p-button-sm" :disabled="true"></Button>
          <Button icon="pi pi-calendar" iconPos="right" :label="returnCalendarLabel" class="p-button-outlined calendar-button p-button-sm" :disabled="true"></Button>
        </div>
      </div>
      <div class="col-12">
        <div class="flex justify-content-between">
          <div v-for="day in calendarWeekdays">
            {{ day }}
          </div>
        </div>
      </div>
    </div>
    <div class="upper-gradient"></div>
    <!-- end of dummy grid -->

    <div class="grid z-1">
      <div class="col-12 px-0">
        <MyCalendar v-model="v$.dates.$model" :inline="true" :minDate="minDate" :maxDate="maxDate" @date-select="selectDate" :selectionMode="computedDateOption" :manualInput="false" placeholder="Dates" :numberOfMonths="13" class="sidebar-calendar" :mobile="true">
        </MyCalendar>
      </div>
    </div>

    <div class="calendar-sidebar-footer h-5rem">
      <div class="lower-gradient mb-2"></div>
      <div class="flex justify-content-center pt-1">
        <Button v-if="showOneWayOption" @click="toggleOneWay" class="one-way-button">{{ oneWayLabel }}</Button>
      </div>
    </div>
  </Sidebar>

  <!-- Calendar Overlay -->
  <OverlayPanel ref="calendarOverlay" appendTo="body" :showCloseIcon="false" class="calendar-overlay" :style="{ top: calendarOverlayPosition }">
    <div>
      <div class="grid">
        <div class="col-6 pl-55">
          <div class="h-full py-2 flex justify-content-center align-items-center">
            <h4 class="my-0">{{ dateSelectionLabel }}</h4>
          </div>
        </div>
        <div class="col-6 pr-55 text-center" v-if="showOneWayOption">
          <Button @click="toggleOneWay" class="one-way-button">
            <div class="text-sm text-white">
              {{ oneWayLabel }}
            </div>
          </Button>
        </div>
      </div>
      <div>
        <MyCalendar id="range" locale="de" v-model="v$.dates.$model" :inline="true" :minDate="minDate" :maxDate="maxDate" @date-select="selectDate($event, $dateMeta)" :selectionMode="computedDateOption" :manualInput="false" placeholder="Dates" :numberOfMonths="2" class="calendar-borderless">
        </MyCalendar>
      </div>
    </div>
  </OverlayPanel>

  <!-- Passenger Picker Overlay -->
  <OverlayPanel ref="passengerPickerOverlay" appendTo="body" :showCloseIcon="false" class="passenger-picker-overlay">
    <div class="col-12 passenger-border-bottom">
      <div class="flex">
        <div class="col-6">
          <div>
            <div class="text-lg text-medium">{{ adultLabel }}</div>
          </div>
          <div>{{ adultSubtitle }}</div>
        </div>
        <div class="col-6 text-right">
          <MyNumberInput :highlightOnFocus="false" class="passenger-incrementor mt-2" @click="preventInput($event)" @keydown="preventInput($event)" v-model="adultCount" :min="minAdultCount" :max="maxAdultPassengers" showButtons buttonLayout="horizontal" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
        </div>
      </div>
    </div>
    <div class="col-12 passenger-border-bottom">
      <div class="flex">
        <div class="col-6">
          <div>
            <div class="text-lg text-medium">{{ childLabel }}</div>
          </div>
          <div>{{ childSubtitle }}</div>
        </div>
        <div class="col-6 text-right">
          <MyNumberInput :highlightOnFocus="false"  class="passenger-incrementor mt-2" @click="preventInput($event)" @keydown="preventInput($event)" v-model="childCount" :min="0" :max="maxChildPassengers" showButtons buttonLayout="horizontal" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="flex">
        <div class="col-6">
          <div>
            <div class="text-lg text-medium">{{ infantLabel }}</div>
          </div>
          <div>{{ infantSubtitle }}</div>
        </div>
        <div class="col-6 text-right">
          <MyNumberInput :highlightOnFocus="false" class="passenger-incrementor mt-2" @click="preventInput($event)" @keydown="preventInput($event)" v-model="infantCount" :min="0" :max="maxInfantPassengers" showButtons buttonLayout="horizontal" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
        </div>
      </div>
    </div>
    <div class="col-12 border-gray-300 border-top-1 h1p mb-2"></div>
    <div class="field-checkbox col-12 px-3 pb-0 cursor-pointer"
         v-for="(classx, index) in cabin">
      <RadioButton :id="'cabinClassCheckbox'+index"
                v-model="cabinClass"
                :value="classx.value"
                @change="hmm($event, classx.value)"/>
      <label class="cursor-pointer" :for="'cabinClassCheckbox'+index" @click="hmm($event, classx.value)">{{ classx.label }}</label>
    </div>

    <!-- <div class="field-checkbox col-12 px-3">
      <Checkbox id="businessClassCheckbox" v-model="businessClass" :binary="true" />
      <label for="businessClassCheckbox">{{ businessClassLabel }}</label>
    </div> -->
  </OverlayPanel>
</section>