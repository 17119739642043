import { computed, onBeforeUnmount, onMounted, onUpdated, ref } from 'vue';
import { useStore } from 'vuex';
import FlightTime from '../../components/FlightTime/FlightTimeComponent.vue';
import MobileStopDrawing from '../../components/MobileStopDrawing/MobileStopDrawingComponent.vue';
import { useRouter } from 'vue-router';
import detailUtils from '../../utils/detailUtils';
import { useToast } from 'primevue/usetoast';
import { expiryTimeForMessage, watchExpire } from '@/utils/watcherNew';
import axios from 'axios';

export default {
  name: 'book-page',
  components: {
    FlightTime,
    MobileStopDrawing
  },
  props: [],
  setup() {
    const store = useStore();
    const toast = useToast(); toast.removeGroup('headless');
    const router = useRouter();

    const paymentData = ref({
      person: {
        gender: '', firstName: '', lastName: '', email: '', contactNumber: '', streetAddress: '', postalCode: '', city: '', country: { label: '', value: '' }, note: ''
      },
      company: {
        companyName: '', companyVAT: '', gender: '', firstName: '', lastName: '', email: '', contactNumber: '', streetAddress: '', postalCode: '', city: '', country: { label: '', value: '' }, note: ''
      }
    });

    const { getAirport, getPriceClass, getAirlineLogo, howManyCols, chunkifyPerks } = detailUtils;

    store.dispatch('setAllFlightSearchImagesLoaded', true);

    const allFlightSearchImagesLoaded = computed(() => store.getters.allFlightSearchImagesLoaded);

    const yearOptions = {};
    const airplaneIcon = 'https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg';
    const currentYear = new Date().getFullYear();
    const passportCountries = [];
    const allowedYearInputSet = {};

    const priceChangeDialogOpened = ref(false);
    const expiryToastAdded = ref(false);
    const selectedOffer = ref(store.getters.flightBookData.content.offers.find(item => item.selected)?.offerId || 1);
    const sessionResolved = ref(false);
    const isFixed = ref(false);
    const checkInvalid = ref(false);
    const passengerData = ref({});
    const val = ref('');
    const date1 = ref();
    const expiryTime = ref(9999);
    const lastExpiryTimeUpdate = ref(Date.now().valueOf() - 1000);
    const detailsAccordionActiveIndex = ref(null);
    const baggageAccordionActiveIndex = ref(0);
    const isSidebarFixed = ref(false);
    const isSidebarDockedToBottom = ref(false);
    const tabViewActiveIndex = ref(0);
    const fareRulesDialogVisible = ref({});
    const toggleFareRulesDialog = (index) => {
      if (!fareRulesDialogVisible.value[`${index + 1}`]) {
        fareRulesDialogVisible.value[`${index + 1}`] = true;
      } else {
        fareRulesDialogVisible.value[`${index + 1}`] = !fareRulesDialogVisible.value[`${index + 1}`];
      }
      // console.log(fareRulesDialogVisible.value);
    };
    const bannedOfferIds = ref([]);

    const totalTravelers = computed(() => store.getters.totalTravelers);
    const device = computed(() => store.getters.device);
    const breadcrumbs = computed(() => store.getters.flightBookData.content.breadcrumbNavigation);
    const language = computed(() => store.getters.language);
    const flightBookData = computed(() => store.getters.flightBookData);
    const selectedOfferData = computed(() => store.getters.flightBookData.content.offers.find(item => item.offerId === selectedOffer.value));
    const flightDetails = computed(() => store.getters.flightDetails);
    const pageLabels = computed(() => store.getters.pageLabels);
    const comparePriceClasses = computed(() => {
      try {
        const a = getPriceClass(flightDetails.value.priceClasses.outbound?.priceCode);
        const b = getPriceClass(flightDetails.value.priceClasses.inbound?.priceCode);
        return JSON.stringify(a) === JSON.stringify(b);
      } catch (error) {
        // console.warn(error);
        return false;
      }
    });
    const totalPassengerCount = computed(() => {
      const x = flightBookData.value.content.offers[0].form.traveler.fields.travelers;
      const a = Number(x.ADT) || 0;
      const c = Number(x.CHD) || 0;
      const i = Number(x.INF) || 0;
      return a + c + i;
    });
    const passengers = computed(() => {
      const x = flightBookData.value.content.offers[0].form.traveler.fields.travelers;
      const a = { ADT: { quantity: Number(x.ADT) || 0 } };
      const c = { CHD: { quantity: Number(x.CHD) || 0 } };
      const i = { INF: { quantity: Number(x.INF) || 0 } };
      return { ...a, ...c, ...i };
    });
    const displayExpiredFlightModal = computed({
      get: () => { return store.getters.displayExpiredFlightModal; },
      set: (newValue) => { store.dispatch('setDisplayExpiredFlightModal', newValue); }
    });
    const selectedOfferSidebarPriceClass = computed(() => {
      const offerId = flightBookData.value.content.offers[selectedOffer.value - 1].offerId;
      const offer = flightBookData.value.content.priceClasses.find(offer => offer.offerId === offerId);
      return offer;
    });

    if (flightBookData.value.a) {
      router.replace(`/${language.value}`);
      router.push(`/${language.value}`);
      window.location.replace(`/${language.value}`);
      return;
    }

    expiryToastAdded.value = false;

    Object.entries(selectedOfferData.value.form.traveler.fields.birthdates).forEach(ageGroup => {
      yearOptions[ageGroup[0]] = [];
      for (var i = Number(ageGroup[1].minDate.split('-')[0]); i <= Number(ageGroup[1].maxDate.split('-')[0]); i++) {
        yearOptions[ageGroup[0]].push(i);
      }
    });

    const formattedYearOptions = (ageGroup) => {
      return yearOptions[ageGroup].map((item) => ({ label: `${item}`, value: item }));
    };

    const monthOptions = (year, ageGroup) => {
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      if (!year) {
        return [];
      }
      let x = arr;
      if (year.value === Number(yearOptions[ageGroup][0])) { // min date
        x = arr.slice(Number(selectedOfferData.value.form.traveler.fields.birthdates[ageGroup].minDate.split('-')[1]) - 1);
      }
      if (year.value === Number(yearOptions[ageGroup].at(-1))) { // max date
        x = arr.slice(0, Number(selectedOfferData.value.form.traveler.fields.birthdates[ageGroup].maxDate.split('-')[1]));
      }
      return x.map((item) => ({ label: `${item}`, value: item }));
    };

    const dayOptions = (year, month, ageGroup) => {
      if (!year || !month || !ageGroup) {
        return [];
      }
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
      let x = arr;
      const endDay = new Date(year.value, month.value, 0).getDate();

      if (year.value === Number(yearOptions[ageGroup][0]) && month.value === monthOptions(year, ageGroup)[0].value) {
        x = arr.slice(Number(selectedOfferData.value.form.traveler.fields.birthdates[ageGroup].minDate.split('-')[2]) - 1);
      }
      if (year.value === Number(yearOptions[ageGroup].at(-1)) && month.value === monthOptions(year, ageGroup).at(-1).value) {
        x = arr.slice(0, Number(selectedOfferData.value.form.traveler.fields.birthdates[ageGroup].maxDate.split('-')[2]));
      }

      return x.slice(0, endDay).map((i) => ({ label: `${i}`, value: i }));
    };

    const yearPassportOptions = (_ageGroup, selectedOfferData) => {
      const minYear = Number(selectedOfferData.value.form.traveler.fields.passport.expireDate.minDate.split('-')[0]);
      const arr = [];
      for (let i = minYear; i <= minYear + 20; i++) {
        arr.push({ label: `${i}`, value: i });
      }
      return arr;
    };

    const monthPassportOptions = (year, ageGroup) => {
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      if (!year) {
        return [];
      }
      let x = arr;
      if (year.value === Number(yearPassportOptions(ageGroup)[0].value)) { // min date
        x = arr.slice(Number(selectedOfferData.value.form.traveler.fields.passport.expireDate.minDate.split('-')[1]) - 1);
      }
      return x.map((item) => ({ label: `${item}`, value: item }));
    };

    const dayPassportOptions = (year, month, ageGroup) => {
      if (!year || !month || !ageGroup) {
        return [];
      }
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
      let x = arr;
      const endDay = new Date(year.value, month.value, 0).getDate();
      if (year.value === Number(yearPassportOptions(ageGroup)[0].value) && month.value === monthPassportOptions(year, ageGroup)[0].value) {
        x = arr.slice(Number(selectedOfferData.value.form.traveler.fields.passport.expireDate.minDate.split('-')[2]) - 1);
      }
      return x.slice(0, endDay).map((i) => ({ label: `${i}`, value: i }));
    };

    const listAirportCodes = (segments) => {
      return segments.slice(1).reduce((previous, segment) => previous + segment.departure.code, '');
    };

    const stickify = () => {
      isFixed.value = window.scrollY >= 262 && window.innerWidth >= 1200;
    };
    window.addEventListener('scroll', stickify);

    initializePassengerData();

    const birthdateOverOrUnder = (age, birthdate, max, min) => {
      const d = birthdate.d;
      const m = birthdate.m;
      const y = birthdate.y;
      let result;
      if (!d || !m || !y || d.length !== 2 || m.length !== 2 || y.length !== 4) {
        result = 'null';
      } else {
        const myDate = `${y}-${m}-${d}`;
        if (myDate < max) {
          result = 'over';
        } else if (myDate > min) {
          result = 'under';
        } else {
          result = 'neither';
        }
      }
      return result;
    };

    const dateIsValid = (dateStr) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;

      if (dateStr.match(regex) === null) {
        return false;
      }

      const date = new Date(dateStr);

      const timestamp = date.getTime();

      if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
      }

      return date.toISOString().startsWith(dateStr);
    };

    const isDate = (dateObject, k, field) => {
      // let min;
      // let max;
      if (field === 'birthdate') {
        // min = new Date(flightBookData.value.content.offers[0].form.traveler.fields.birthdates[k].minDate);
        // max = new Date(flightBookData.value.content.offers[0].form.traveler.fields.birthdates[k].maxDate);
      } else {
        // min = new Date(flightBookData.value.content.offers[0].form.traveler.fields.passport.expireDate.minDate);
        // max = new Date(flightBookData.value.content.offers[0].form.traveler.fields.passport.expireDate.maxDate);
      }
      const { y, m, d } = dateObject;
      if (!y || !m || !d) {
        return false;
      }
      const ylen = y.length === 4;
      const mlen = m.length === 2;
      const dlen = d.length === 2;
      if (ylen && mlen && dlen && dateIsValid(`${y}-${m}-${d}`)) {
        // const nd = new Date(`${y}-${m}-${d}`);
        // return nd >= min && nd <= max;
        return true;
      }

      return false;
    };

    setTimeout(() => {
      fetch(`https://putnik-backend.ibe-servers.com/${language.value}/countries`)
        .then((res) => res.json())
        .then((data) => {
          const isPassportNecessary = flightBookData.value.content.offers[0].form.traveler.fields.passport || null;
          if (isPassportNecessary) {
            data.forEach(country => passportCountries.push({ label: country.name, value: country.code }));
            Object.entries(passengerData.value).forEach(person => {
              person[1].passportCountry = passportCountries.find(element => {
                const a = element.value === selectedOfferData.value.form.traveler.fields.passport.passportCountry.selected;
                return a;
              }).value;
              person[1].citizenship = person[1].passportCountry;
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }, 3000);

    const prepareRequestObject = () => {
      const isPassportNecessary = flightBookData.value.content.offers[0].form.traveler.fields.passport || null;
      const sanitizedTravelers = [];

      Object.values(passengerData.value).forEach(v_ => {
        const basic = {
          type: v_.type,
          gender: v_.gender,
          firstName: v_.firstName,
          lastName: v_.lastName,
          birthdate: `${v_.birthdate.y}-${v_.birthdate.m}-${v_.birthdate.d}`
        };
        if (basic.type === 'ADT') {
          basic.title = v_.title;
        } else {
          basic.title = '';
        }
        const passport = {
          passport: isPassportNecessary && {
            number: v_.passportNumber,
            passportCountry: v_.passportCountry,
            citizenship: v_.citizenship,
            expireDate: `${v_.passportDate.y}-${v_.passportDate.m}-${v_.passportDate.d}`
          }
        };
        if (!isPassportNecessary) {
          sanitizedTravelers.push(basic);
        } else {
          sanitizedTravelers.push({ ...basic, ...passport });
        }
      });

      const toReturn = {
        session: store.getters.flightBookData.config.session.value,
        offerId: selectedOffer.value.toString(),
        travelers: sanitizedTravelers,
        buyer: {
          gender: paymentData.value.person.gender || paymentData.value.company.gender,
          firstName: paymentData.value.person.firstName || paymentData.value.company.firstName,
          lastName: paymentData.value.person.lastName || paymentData.value.company.lastName,
          email: paymentData.value.person.email || paymentData.value.company.email,
          contactNumber: paymentData.value.person.contactNumber || paymentData.value.company.contactNumber,
          streetAddress: paymentData.value.person.streetAddress || paymentData.value.company.streetAddress,
          postalCode: paymentData.value.person.postalCode || paymentData.value.company.postalCode,
          city: paymentData.value.person.city || paymentData.value.company.city,
          country: paymentData.value.person.country || paymentData.value.company.country,
          note: paymentData.value.person.note || paymentData.value.company.note || ''
        }
      };

      if (paymentData.value.company.companyName) {
        toReturn.buyer.companyName = paymentData.value.company.companyName;
      }
      if (paymentData.value.company.companyVAT) {
        toReturn.buyer.companyVAT = paymentData.value.company.companyVAT;
      }

      return toReturn;
    };

    const entity = computed(() => ['person', 'company'][tabViewActiveIndex.value]);

    const getPaymentData = async () => {
      try {
        const a = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/payment/${store.getters.paymentParamsEncoded}`);
        const b = await a.json();
        if (b.data.content && 'error' in b.data.content) {
          if (b.data.error.redirectUrl) {
            router.push(b.data.error.redirectUrl);
          }
          setTimeout(() => {
            toast.add({ group: 'headless', severity: 'warn', summary: '', detail: b.data.error.showMessage, life: 30000 });
            store.dispatch('setLoading', false);
          }, 1000);
          return;
        }
        await store.dispatch('setPaymentData', b);
        if ('loader' in b.data || (b.data.content && 'loader' in b.data.content)) {
          setTimeout(() => {
            store.dispatch('setLoader', b.data.loader || (b.data.content && 'loader' in b.data.content && b.data.content.loader));
          }, 5000);
        }
        const paymentConfig = b.data.config;
        store.dispatch('setSession', paymentConfig.session);

        store.dispatch('setPaymentParamsEncoded', paymentConfig.paymentParamsEncoded || paymentConfig.bookParamsEncoded);
        window.localStorage.setItem('session', paymentConfig.session.value);
        window.localStorage.setItem('paymentParamsEncoded', paymentConfig.paymentParamsEncoded || paymentConfig.bookParamsEncoded);
        //
        router.push(`/${language.value}/flight/payment`);
      } catch (err) {
        console.error(err);
        await store.dispatch('setPaymentData', {});
      }
    };

    const proceedToPayment = async () => {
      store.dispatch('setShouldGetLoaderDataOnMounted', false);
      checkInvalid.value = true;
      setTimeout(() => {
        checkInvalid.value = false;
      }, 2000);

      let buyerDataValidated = false;

      Object.keys(paymentData.value).forEach(key => {
        if (key === 'person') {
          console.log('checking person');
          if (
            paymentData.value.person.gender !== ''
            && paymentData.value.person.firstName !== ''
            && paymentData.value.person.lastName !== ''
            && paymentData.value.person.email !== ''
            && paymentData.value.person.contactNumber !== ''
            && paymentData.value.person.streetAddress !== ''
            && paymentData.value.person.postalCode !== ''
            && paymentData.value.person.city !== ''
            && paymentData.value.person.country !== ''
          ) {
            if (tabViewActiveIndex.value === 0) {
              buyerDataValidated = true; entity.value = 'person';
            }
          }
        }
        if (key === 'company') {
          console.log('checking company');
          if (
            paymentData.value.company.companyName !== ''
            && paymentData.value.company.companyVAT !== ''
            && paymentData.value.company.gender !== ''
            && paymentData.value.company.firstName !== ''
            && paymentData.value.company.lastName !== ''
            && paymentData.value.company.email !== ''
            && paymentData.value.company.contactNumber !== ''
            && paymentData.value.company.streetAddress !== ''
            && paymentData.value.company.postalCode !== ''
            && paymentData.value.company.city !== ''
            && paymentData.value.company.country !== ''
          ) {
            if (!buyerDataValidated) {
              buyerDataValidated = true; entity.value = 'company';
            }
          }
        }
      });

      if (!buyerDataValidated) {
        return;
      }

      const passengersOkay = validatePassengers();
      if (!passengersOkay) {
        setTimeout(async () => {
          try {
            const firstInvalidElement = document.querySelector('.p-error').parentElement.parentElement.parentElement;
            firstInvalidElement.scrollIntoView({ behavior: 'smooth' });
          } catch (error) {
            // do nothing
          }
        }, 50);
      } else {
        store.dispatch('setLoading', true);
        try {
          clearInterval(sessionCheckIntervalId);

          const response = await axios.post(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/payment`, prepareRequestObject());

          if (!response.status >= 400) {
            throw new Error('Network response was not ok');
          }

          const responseData = response.data;

          if (responseData.status === 'failure' && responseData.data?.error) {
            if (responseData.data.error.code === 'FP-UF') {
              bannedOfferIds.value.push(selectedOffer.value);
              selectedOffer.value = store.getters.flightBookData.content.offers.filter(item => !bannedOfferIds.value.includes(item.offerId))[0].offerId;
            }

            console.error(responseData.data.error);
            if (responseData.data.error.redirectUrl) {
              router.push(responseData.data.error.redirectUrl);
              setTimeout(() => {
                store.dispatch('setLoading', false);
              }, responseData.data.error.redirectUrl.length < 5 ? 5000 : undefined);
            }
            setTimeout(() => {
              sessionCheckIntervalId = resetSessionCheckInterval();
              store.dispatch('setLoading', false);
              toast.add({ group: 'headless', severity: 'warn', summary: '', detail: responseData.data.error.showMessage, life: 30000 });
            }, 1000);
            return;
          } else if (responseData.status !== 'success') {
            return;
          }

          store.dispatch('setSession', responseData.data.config.session);
          store.dispatch('setPaymentParamsEncoded', responseData.data.config.paymentParamsEncoded || responseData.data.config.bookParamsEncoded);
          getPaymentData();
        } catch (error) {
          console.error('Error processing payment:', error);
        } finally {
          // store.dispatch('setLoading', false);
        }
      }
    };

    const regexify = (charset, passenger, field) => {
      const regex = new RegExp(`[^${charset}]+`);
      passengerData.value[passenger][field] = passengerData.value[passenger][field].replace(regex, '');
    };

    const regexifyBuyer = (charset, personOrLegal, field, _event) => {
      const regex = new RegExp(`[^ ${charset}]+`, 'g');
      paymentData.value[personOrLegal][field] = paymentData.value[personOrLegal][field].replace(regex, '');
    };

    const selectDefaultDropdownYear = () => {
      if (!passengerData.value.ADT0.birthdate.y || !Object.keys(passengerData.value.ADT0.birthdate.y).includes('label')) {
        passengerData.value.ADT0.birthdate.y = { label: '1980', value: 1980 };
      }
    };

    if (!window.localStorage.getItem('session') || !window.localStorage.getItem('bookParamsEncoded')) {
      // console.warn('no session or params, kicking to home pages');
      router.push(`/${language.value}`);
    } else {
      store.dispatch('setBookSession', window.localStorage.getItem('session'));
      store.dispatch('setBookParamsEncoded', window.localStorage.getItem('bookParamsEncoded'));
      getBookDataOnRefresh();
    }

    const handleSidebarPosition = (e) => {
      try {
        const mainContainer = document.getElementById('main-container');
        const mainContainerY = Math.max(mainContainer.offsetTop, mainContainer.scrollTop, mainContainer.clientTop);
        const mainContainerHeight = Math.max(mainContainer.clientHeight, mainContainer.offsetHeight, mainContainer.scrollHeight);
        const mainContainerBottomY = mainContainerY + mainContainerHeight;

        const sideCard = document.getElementById('side-card');
        const sideCardHeight = Math.max(sideCard.clientHeight, sideCard.offsetHeight, sideCard.scrollHeight);
        const sideCardBottomY = sideCardHeight;

        isSidebarFixed.value = window.scrollY > 265; // && fromBottom > 200;

        isSidebarDockedToBottom.value = sideCardBottomY + window.scrollY + 62 > mainContainerBottomY && window.visualViewport.width > 1200;

        isSidebarFixed.value = window.scrollY > 265 && !isSidebarDockedToBottom.value && window.visualViewport.width > 1200;
      } catch (error) {
        isSidebarFixed.value = false;
        isSidebarDockedToBottom.value = false;
      }
    };

    window.addEventListener('scroll', handleSidebarPosition);

    let start = 1900;
    let end = new Date().getFullYear();
    let allowedYearValues = ['1', '2', '19', '20'];
    for (let i = start; i < end; i++) {
      allowedYearValues.push(i.toString());
      allowedYearValues.push(i.toString().slice(0, 3).toString());
    }

    allowedYearInputSet.birthdate = [...new Set(allowedYearValues)].sort((a, b) => Number(a) - Number(b));

    start = new Date().getFullYear();
    end = start + 20;
    allowedYearValues = ['2', '20'];
    for (let i = start; i < end; i++) {
      allowedYearValues.push(i.toString());
      allowedYearValues.push(i.toString().slice(0, 3).toString());
    }
    allowedYearInputSet.passportDate = [...new Set(allowedYearValues)].sort((a, b) => Number(a) - Number(b));

    const checkYear = (passenger, field, age) => {
      const toCheck = passengerData.value[passenger][field].y;
      passengerData.value[passenger][field].y = toCheck.replace(/\D/g, '');
    };
    const addLeadingZero = (passenger, field, age, nestedField) => {
      const toCheck = passengerData.value[passenger][field][nestedField];
      if (toCheck && toCheck.length === 1) {
        passengerData.value[passenger][field][nestedField] = '0' + toCheck;
      }
    };

    const resetField = (passenger, field, nestedField) => {
      passengerData.value[passenger][field][nestedField] = '';
    };

    const checkDay = (passenger, field, age) => {
      const allowedValues = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
      const toCheck = passengerData.value[passenger][field].d;
      if (!(allowedValues.some(v_ => v_ === toCheck))) {
        passengerData.value[passenger][field].d = '';
      }
      if (['4', '5', '6', '7', '8', '9'].some(v => v === toCheck)) {
        passengerData.value[passenger][field].d = '0' + toCheck;
      }
    };
    const checkMonth = (passenger, field, age) => {
      const allowedValues = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
      const toCheck = passengerData.value[passenger][field].m;
      if (!(allowedValues.some(v_ => v_ === toCheck))) {
        passengerData.value[passenger][field].m = '';
      }
      if (['2', '3', '4', '5', '6', '7', '8', '9'].some(v => v === toCheck)) {
        passengerData.value[passenger][field].m = '0' + toCheck;
      }
    };

    setTimeout(() => {
      try {
        const z = document.getElementsByClassName('psngr');
        z.item(z.length - 1).className = 'col-12 p-2 border-bottom-0 psngr';
      } catch (error) {
        // console.error(error);
      }
    }, 1400);

    if (!store.getters.isExpiryBeingWatched) {
      //  watchExpire(expiryTime, lastExpiryTimeUpdate, expiryToastAdded, toast, flightBookData.value.content.pageLabels.timer.headline, displayExpiredFlightModal);
      store.dispatch('setIsExpiryBeingWatched', true);
    }

    setTimeout(() => {
      expiryTime.value = flightBookData.value.config.session.ttl;
      store.dispatch('setExpiry', Date.now().valueOf() + flightBookData.value.config.session.ttl * 1000);
    }, 500);

    const retrySearch = () => {
      // console.log(flightBookData.value.content.breadcrumbNavigation);
      window.location.href = window.location.origin + flightBookData.value.content.breadcrumbNavigation.items[0].url;
    };

    onUpdated(() => {
      try {
        if (store.getters.loading !== true) {
          setTimeout(() => {
            let flightTimeElements;
            let flightNumberElements = [];
            let mobileStopDrawingElements = [];
            if (detailsAccordionActiveIndex.value === 0) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-accordion .flight-time'));
              flightNumberElements = Array.from(document.querySelectorAll('.p-accordion .flight-number .flight-code'));
              // eslint-disable-next-line no-dupe-else-if
            } else if (detailsAccordionActiveIndex.value === 0) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-accordion .flight-time'));
              mobileStopDrawingElements = Array.from(document.querySelectorAll('.mobile-stop-drawing'));
              flightNumberElements = Array.from(document.querySelectorAll('p-accordion .flight-number .flight-code'));
            } else {
              flightTimeElements = Array.from(document.querySelectorAll('p-accordion .single-flight .flight-time'));
            }
            const b = flightTimeElements.map(item => item.offsetWidth);
            const c = Math.max.apply(null, b);
            flightTimeElements.forEach(element => {
              element.style.cssText = `width: ${c}px`;
            });
            const d = flightNumberElements.map(item => item.offsetWidth);
            const e = Math.max.apply(null, d);
            flightNumberElements.forEach(element => {
              element.style.cssText = `width: ${e}px; text-align: right; white-space: nowrap;`;
            });
            const screenWidth = window.innerWidth;
            const mobileStopDrawingWidth = screenWidth - 40 - 2 * c;
            mobileStopDrawingElements.forEach(element => {
              element.style.cssText = `width: ${mobileStopDrawingWidth}px`;
            });
          }, 50);
        }
      } catch (error) {
        //
      }
    });

    const adjustPerkContainerHeights = () => {
      const perkContainers = document.querySelectorAll('.perk-container');
      const maxPerkContainerHeight = Math.max.apply(null, Array.from(perkContainers).map(item => item.offsetHeight));
      perkContainers.forEach(element => {
        element.style.cssText = `height: ${maxPerkContainerHeight}px;`;
      });
    };

    const passportCountriesBuyer = [];

    onMounted(() => {
      // create an array of numbers from 1000 to 2000 in 100 increments
      const numbers = Array.from(Array(11).keys()).map(i => (i + 10) * 100);
      // for each number, create a setTimeout to call the function
      numbers.forEach((number) => {
        setTimeout(() => {
          adjustPerkContainerHeights();
        }, number);
      });

      fetch(`https://putnik-backend.ibe-servers.com/${language.value}/countries`)
        .then((res) => res.json())
        .then((data) => {
          data.forEach(country => passportCountriesBuyer.push({ label: country.name, value: country.code }));
          Object.entries(paymentData.value).forEach(entity => {
            entity[1].country = passportCountriesBuyer.find(element => {
              // console.log(element);
              const a = element.value === flightBookData.value.content.offers[0].form.buyer.fields.country.selected;
              return a;
            }).value;
          });
        })
        .catch(err => {
          console.error(err);
        });
    });

    const goHome = () => {
      toast.removeAllGroups();
      router.push(`/${store.getters.language}`);
    };

    const checkAgainstRegex = (field, regex) => {
      // console.log('checking against regex', field, regex);
      const r = new RegExp(regex);
      const isMatch = field?.toString().match(r);
      return isMatch;
    };

    let sessionCheckIntervalId = null;
    sessionCheckIntervalId = setInterval(() => {
      // console.log('here');
      if (`${store.getters.bookSession}` === 'undefined' || `${store.getters.bookSession}` === '') {
        return;
      }
      axios.get(`https://${store.getters.mainData.config.baseUrl}/sr/flight/get-session/${store.getters.bookSession}`).then(response => {
        // console.log('response.data: ', response.data.data.ttl);
        expiryTime.value = response.data.data.ttl;

        if (expiryTime.value > 300) {
          toast.removeGroup('expiry');
          expiryToastAdded.value = false;
          // console.log('Expiry still greater than 300 seconds, not adding toast');
        }

        const now = new Date();
        const expiryDate = new Date(Date.now() + expiryTime.value * 1000);
        const remaining = (expiryDate - now) / 1000;

        store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

        if (!expiryToastAdded.value && expiryTime.value < 300) {
          // console.log('lets goooooo');
          toast.removeGroup('expiry');
          setTimeout(() => {
            toast.add({
              severity: 'info',
              group: 'expiry',
              summary: flightBookData.value.content.pageLabels.timer.headline,
              detail: computed(() => store.getters.expiryTimeForMessage),
              life: (remaining + 1) * 1000
            });
          }, 10);
          expiryToastAdded.value = true;
        } else {
          // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
        }
        if (remaining <= 1) {
          clearInterval(sessionCheckIntervalId);
          toast.removeAllGroups();
          // console.log('Session watcher expired');
          displayExpiredFlightModal.value = true;
        } else {
          displayExpiredFlightModal.value = false;
        }
      }).catch(error => {
        console.error(error);
      });
    }, 1000);

    store.dispatch('setBookToPay', true);

    onBeforeUnmount(() => {
      clearInterval(sessionCheckIntervalId);
      toast.removeGroup('expiry');
    });

    const fareRulesSidebarDialogVisible = ref(false);
    const toggleFareRulesSidebarDialog = () => {
      fareRulesSidebarDialogVisible.value = !fareRulesSidebarDialogVisible.value;
    };

    const autofillPassengerDataOnePassenger = () => {
      passengerData.value.ADT0.title = 'MR';
      passengerData.value.ADT0.firstName = 'John';
      passengerData.value.ADT0.lastName = 'Doe';
      passengerData.value.ADT0.gender = 'M';
      passengerData.value.ADT0.birthdate.y = '1990';
      passengerData.value.ADT0.birthdate.m = '01';
      passengerData.value.ADT0.birthdate.d = '01';
      // passportCountry
      // passportNumber
      // citizenship
      // passportDate
      paymentData.value.person.gender = 'M';
      paymentData.value.person.firstName = 'John';
      paymentData.value.person.lastName = 'Doe';
      paymentData.value.person.email = 'johndoe@mail.com';
      paymentData.value.person.contactNumber = '+1234567890';
      paymentData.value.person.streetAddress = '123 Main St';
      paymentData.value.person.postalCode = '12345';
      paymentData.value.person.city = 'Belgrade';
      paymentData.value.person.country = 'RS';
      paymentData.value.person.note = 'This is a note';
    };

    return {
      autofillPassengerDataOnePassenger,
      allFlightSearchImagesLoaded,
      airplaneIcon,
      baggageAccordionActiveIndex,
      bannedOfferIds,
      breadcrumbs,
      checkInvalid,
      comparePriceClasses,
      console: console,
      currentYear,
      date1,
      detailsAccordionActiveIndex,
      device,
      displayExpiredFlightModal,
      expiryTime,
      flightBookData,
      flightDetails,
      isFixed,
      isSidebarDockedToBottom,
      isSidebarFixed,
      language,
      pageLabels,
      passengerData,
      passengers,
      passportCountries,
      passportCountriesBuyer,
      paymentData,
      selectedOffer,
      selectedOfferData,
      selectedOfferSidebarPriceClass,
      sessionResolved,
      tabViewActiveIndex,
      totalPassengerCount,
      totalTravelers,
      val,
      yearOptions,
      addLeadingZero,
      birthdateOverOrUnder,
      checkAgainstRegex,
      checkDay,
      checkMonth,
      checkYear,
      chunkifyPerks,
      dayOptions,
      dayPassportOptions,
      formattedYearOptions,
      getAirlineLogo,
      getAirport,
      getPriceClass,
      goHome,
      howManyCols,
      isDate,
      listAirportCodes,
      monthOptions,
      monthPassportOptions,
      regexify,
      regexifyBuyer,
      resetField,
      retrySearch,
      selectDefaultDropdownYear,
      proceedToPayment,
      yearPassportOptions,
      priceChangeDialogOpened,
      fareRulesDialogVisible,
      fareRulesSidebarDialogVisible,
      toggleFareRulesDialog,
      toggleFareRulesSidebarDialog,
      returnFareRules: (id) => {
        // console.log(id);
        // .fareRules.replaceAll('courier', '').replaceAll('\<div>', '\<div class=\'justify-content-beteween\'>')
        return 'HELLORULES';
      }
    };

    function resetSessionCheckInterval() {
      // console.log('resetting session check interval');
      const setIntervalFunction = () => {
        // console.log('here');
        if (`${store.getters.bookSession}` === 'undefined' || `${store.getters.bookSession}` === '') {
          return;
        }
        axios.get(`https://${store.getters.mainData.config.baseUrl}/sr/flight/get-session/${store.getters.bookSession}`).then(response => {
          // console.log('response.data: ', response.data.data.ttl);
          expiryTime.value = response.data.data.ttl;

          if (expiryTime.value > 300) {
            toast.removeGroup('expiry');
            expiryToastAdded.value = false;
            // console.log('Expiry still greater than 300 seconds, not adding toast');
          }

          const now = new Date();
          const expiryDate = new Date(Date.now() + expiryTime.value * 1000);
          const remaining = (expiryDate - now) / 1000;

          store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

          if (!expiryToastAdded.value && expiryTime.value < 300) {
            // console.log('lets goooooo');
            toast.removeGroup('expiry');
            setTimeout(() => {
              toast.add({
                severity: 'info',
                group: 'expiry',
                summary: flightBookData.value.content.pageLabels.timer.headline,
                detail: computed(() => store.getters.expiryTimeForMessage),
                life: (remaining + 1) * 1000
              });
            }, 10);
            expiryToastAdded.value = true;
          } else {
            // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
          }
          if (remaining <= 1) {
            clearInterval(sessionCheckIntervalId);
            toast.removeAllGroups();
            // console.log('Session watcher expired');
            displayExpiredFlightModal.value = true;
          } else {
            displayExpiredFlightModal.value = false;
          }
        }).catch(error => {
          console.error(error);
        });
      };

      return setInterval(setIntervalFunction, 1000);
    }

    function populatePassengerData() {
      const isPassportNecessary = flightBookData.value.content.offers[0].form.traveler.fields.passport || null;
      Object
        .entries(flightBookData.value.content.offers[0].form.traveler.fields.presetTravelers)
        .forEach(kvPair => {
          const [key, value] = kvPair;
          // Prepare object
          const presetPassenger = {
            type: value.type,
            gender: value.gender,
            lastName: value.lastName,
            firstName: value.firstName,
            birthdate: value.birthdate,
            birthdateFull: '',
            passportCountry: isPassportNecessary && passportCountries.find(c => { return c.value === value.passport.passportCountry; }),
            passportNumber: isPassportNecessary && value.passport.number,
            passportDate: isPassportNecessary && value.passport.expireDate,
            passportDateFull: '',
            citizenship: isPassportNecessary && passportCountries.find(c => { return c.value === value.passport.citizenship; })
          };
          if (value.type === 'ADT') {
            presetPassenger.title = value.title;
          } else {
            presetPassenger.title = '';
          }
          passengerData.value[key] = presetPassenger;
        });

      // console.log('Checking presetBuyer', flightBookData.value.content.offers[0].form.buyer.fields.presetBuyer);
      if (Object.keys(flightBookData.value.content.offers[0].form.buyer.fields.presetBuyer)) {
        // console.log('populating buyer');
        const presetData = flightBookData.value.content.offers[0].form.buyer.fields.presetBuyer;

        if (presetData.type === 'legal') {
          // console.log('populating legal buyer');
          tabViewActiveIndex.value = 1;
          paymentData.value.company.companyName = presetData.companyName;
          paymentData.value.company.companyVAT = presetData.companyVAT;
          paymentData.value.company.gender = presetData.gender;
          paymentData.value.company.firstName = presetData.firstName;
          paymentData.value.company.lastName = presetData.lastName;
          paymentData.value.company.email = presetData.email;
          paymentData.value.company.contactNumber = presetData.contactNumber;
          paymentData.value.company.streetAddress = presetData.streetAddress;
          paymentData.value.company.postalCode = presetData.postalCode;
          paymentData.value.company.city = presetData.city;
          paymentData.value.company.country = presetData.country;
          paymentData.value.company.note = presetData.note;
        }
        if (presetData.type === 'natural') {
          // console.log('populating natural buyer');
          tabViewActiveIndex.value = 0;
          paymentData.value.person.companyName = presetData.companyName;
          paymentData.value.person.companyVAT = presetData.companyVAT;
          paymentData.value.person.gender = presetData.gender;
          paymentData.value.person.firstName = presetData.firstName;
          paymentData.value.person.lastName = presetData.lastName;
          paymentData.value.person.email = presetData.email;
          paymentData.value.person.contactNumber = presetData.contactNumber;
          paymentData.value.person.streetAddress = presetData.streetAddress;
          paymentData.value.person.postalCode = presetData.postalCode;
          paymentData.value.person.city = presetData.city;
          paymentData.value.person.country = presetData.country;
          paymentData.value.person.note = presetData.note;
        }
      }
    }

    function initializePassengerData() {
      Object
        .entries(flightBookData.value.config.searchParams.travelers)
        .map(age => {
          const y = {};
          for (let i = 0; i < Number(age[1]); i++) {
            y[`${age[0]}${i}`] = {
              type: age[0],
              firstName: '',
              lastName: '',
              gender: null,
              birthdate: { d: null, m: null, y: null },
              birthdateFull: '',
              passportCountry: '',
              passportNumber: '',
              passportDate: { d: null, m: null, y: null },
              passportDateFull: '',
              citizenship: ''
            };
          }
          return y;
        })
        .forEach(x => Object.entries(x).forEach(y => { passengerData.value[y[0]] = y[1]; }));
    }

    function validatePassengers() {
      const fieldstore = flightBookData.value.content.offers[0].form.traveler.fields;
      const isPassportNecessary = fieldstore.passport || null;
      let passengersOkay = 0;
      Object.values(passengerData.value).forEach(passenger => {
        if (
          passenger.firstName !== ''
          && checkAgainstRegex(passenger.firstName, fieldstore.names.firstName.regex)
          && passenger.lastName !== ''
          && checkAgainstRegex(passenger.lastName, fieldstore.names.lastName.regex)
          && checkAgainstRegex(`${passenger.firstName} ${passenger.lastName}`, fieldstore.names.firstNameLastName.regex)
          && passenger.gender !== null
          && (passenger.type !== 'ADT' || passenger.title !== null)
          && passenger.birthdate.d !== null
          && passenger.birthdate.m !== null
          && passenger.birthdate.y !== null
          && (!isPassportNecessary || passenger.passportCountry !== '')
          && (!isPassportNecessary || passenger.passportNumber !== '')
          && (!isPassportNecessary || checkAgainstRegex(passenger.passportNumber, fieldstore.passport.number.regex))
          && (!isPassportNecessary || passenger.passportDate.d !== null)
          && (!isPassportNecessary || passenger.passportDate.m !== null)
          && (!isPassportNecessary || passenger.passportDate.y !== null)
          && (!isPassportNecessary || passenger.citizenship !== '')
          && birthdateOverOrUnder(passenger.type, passenger.birthdate, fieldstore.birthdates[passenger.type].minDate, fieldstore.birthdates[passenger.type].maxDate) === 'neither'
          && (!isPassportNecessary || birthdateOverOrUnder(passenger.type, passenger.passportDate, fieldstore.passport.expireDate.minDate, fieldstore.passport.expireDate.maxDate) === 'neither')
        ) {
          // passengersOkay = true;
        } else {
          passengersOkay--;
        }
      });
      return passengersOkay === 0;
    }

    async function getBookDataOnRefresh() {
      if (store.getters.comingFromSearchToBook) {
        store.dispatch('setLoading', true);
      }
      store.dispatch('setLoading', true);
      store.dispatch('setComingFromSearchToBook', false);
      try {
        const a = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/book/${store.getters.bookParamsEncoded}`);
        const b = await a.json();

        if (b.data && 'error' in b.data) {
          if (b.data.error.redirectUrl) {
            router.push(b.data.error.redirectUrl);
          }
          if (b.data.error.showMessage.redirectUrl) {
            router.push(b.data.error.showMessage.redirectUrl);
          }
          setTimeout(() => {
            if (!`${b.data.error.code}`.includes('-SE') && !`${b.data.error.code}`.includes('-UR')) {
              const showMessage = b.data.error.showMessage;
              toast.add({ group: 'headless', severity: 'warn', summary: showMessage.headline, detail: `${showMessage.subtitle} ${showMessage.text}`, life: 30000 });
            }
            store.dispatch('setLoading', false);
          }, 200);
          return;
        }

        await store.dispatch('setFlightBookData', b.data);

        selectedOffer.value = store.getters.flightBookData.content.offers.find(item => item.selected).offerId;

        window.localStorage.setItem('session', b.data.config.session.value);
        // window.localStorage.setItem('paymentSession', b.data.config.session.value);
        window.localStorage.setItem('bookParamsEncoded', b.data.config.paymentParamsEncoded || b.data.config.bookParamsEncoded);
        if (flightBookData.value.content.offers[0].priceChange?.isChanged) {
          setTimeout(() => {
            priceChangeDialogOpened.value = true;
          }, 1000);
        }
        initializePassengerData();
        populatePassengerData();
        setTimeout(() => {
          sessionResolved.value = true;
          store.dispatch('setLoader', flightBookData.value.loader);
          store.dispatch('setLoading', false);
        }, 1000);
      } catch (err) {
        console.error(err);
        await store.dispatch('setFlightBookData', { err: 'err' });
        router.push(`/${language.value}`);
        store.dispatch('setLoading', false);
      }
    }
  }
};
