<section class="contact-page"
         v-if="!isLoading">
  <Header />
  <div data-v-53220066=""
  class="flex justify-content-center mt-3 xl:mt-5">
  <div data-v-53220066=""
  class="dynwid px-2">
  <h2 data-v-53220066=""
  class="font-semibold mt-5 mb-2"
  style="font-size: 1.5em;">{{contactData.content.headline}}</h2>
    </div>
  </div>
  <!-- <div class="flex flex-wrap">

    <div class="col-12">
      <div class="flex justify-content-center">
        <div class="col-12bg-white dynwid">
          <h2 class="mb-0 font-semibold"
              "></h2>
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-12 p-0 pb-2 xl:pt-1">
    <div class="flex justify-content-center detail-container xl:pt-5 pb-5">
      <div class="pr-0 pl-0 pt-0 bg-white detail-card">
        <div v-if="contactData.content.map">
          <div class="col-12 p-0 pb-2">
            <iframe :src="contactData.content.map.google.src"
                    frameborder="0"
                    class="gmap"></iframe>
          </div>
        </div>
        <div class="flex justify-content-center">
          <div class="col-12 xl:col-11">
            <div class="flex flex-wrap justify-content-between">
              <div class="col-12 lg:col-6 details">
                <div class="grid">
                  <div class="col-12">
                    <div class="flex align-content-start address">
                      <div class="mr-1">
                        <img :src="contactData.content.info.address.icon.url"
                             alt=""
                             class="w1e pt-1">
                      </div>
                      <div class="list pl-2">
                        <h4 class="m-0 pb-2">{{ contactData.content.info.address.label }}</h4>
                        <div class="pb-2"
                             v-for="text in contactData.content.info.address.text">{{ text }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="flex align-content-start phone">
                      <div class="mr-1">
                        <img :src="contactData.content.info.phone.icon.url"
                             alt=""
                             class="w1e pt-1">
                      </div>
                      <div class="list pl-2">
                        <h4 class="m-0 pb-2">{{ contactData.content.info.phone.headline }}</h4>
                        <a class="phone-number no-underline"
                           :href="'tel:' + contactData.content.info.phone.text">
                          <strong v-for="text in contactData.content.info.phone.text"
                                  class="phone-number">{{ text
                            }}</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="grid work-hours">
                      <div class="mr-1">
                        <img :src="contactData.content.info.workingHours.icon.url"
                             alt=""
                             class="w1e pt-1">
                      </div>
                      <div class="list col-11 xl:col-11 pt-0">
                        <h4 class="m-0 pb-2">{{ contactData.content.info.workingHours.headline }}</h4>
                        <div v-if="contactData.content.info.workingHours.callCenter">
                          <!-- <h5 class="mt-2 mb-0">{{ contactData.content.info.workingHours.callCenter.subtitle }}</h5> -->
                          <div v-for="text in contactData.content.info.workingHours.callCenter.text"
                               class="grid">
                            <div class="col-12 p-0 pb-2">{{ text }}</div>
                          </div>
                        </div>

                        <div v-if="contactData.content.info.workingHours.office">
                          <h5 class="mt-2 mb-0">{{ contactData.content.info.workingHours.office.subtitle }}</h5>
                          <div v-for="text in contactData.content.info.workingHours.office.text"
                               class="grid">
                            <div class="col-12 p-0 pb-2">{{ text }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 lg:col-6 email-form">
                <!-- contactData.content.form.fields -->
                <div class="flex justify-content-center">
                  <div v-if="v$ && v$.value.name"
                       class="col-12 px-0 lg:px-2">
                    <form @submit.prevent="handleSubmit(!v$.value.$invalid)"
                          class="p-fluid">
                      <div class="field mb-4">
                        <div class="list p-float-label">
                          <div class="pb-1">{{ contactData.content.pageLabels.form.name }}</div>
                          <InputText id="name"
                                     v-model="v$.value.name.$model" />
                        </div>
                        <small v-if="v$.value.name.$invalid && submitted"
                               class="absolute p-error mt-0">
                          <small v-for="(error, index) of v$.value.name.$silentErrors"
                                 :key="index">
                            <small class="p-error text-xs">{{ error.$message }}</small>
                          </small>
                        </small>
                      </div>
                      <div class="field mb-4">
                        <div class="list p-float-label">
                          <div class="pb-1">{{ contactData.content.pageLabels.form.email }}</div>
                          <InputText id="email"
                                     v-model="v$.value.email.$model"
                                     aria-describedby="email-error" />
                        </div>
                        <small class="absolute p-error mt-0"
                             v-if="v$.value.email.$invalid && submitted">
                          <small v-for="(error, index) of v$.value.email.$silentErrors"
                                 :key="index">
                            <small class="p-error text-xs">{{ error.$message }}</small>
                          </small>
                        </small>
                      </div>
                      <div class="field mb-4">
                        <div class="list p-float-label">
                          <div class="pb-1">{{ contactData.content.pageLabels.form.message }}</div>
                          <TextArea id="message"
                                    v-model="v$.value.message.$model"
                                    :autoResize="true"
                                    aria-describedby="message-error"
                                    class="-mb-1"
                                    rows="3"
                                    maxlength="1000" />
                        </div>
                        <span v-if="v$.value.message.$error && submitted">
                          <span id="message-error -mt-1"
                                v-for="(error, index) of v$.value.message.$silentErrors"
                                :key="index">
                            <small class="absolute p-error text-xs">{{ error.$message }}</small>
                          </span>
                        </span>
                        <small v-else-if="(v$.value.message.$invalid && submitted) || v$.value.message.$pending.$response"
                               class="text-xs absolute p-error mt-0">{{
                          v$.value.message.required.$message.replace('Value is required',
                          contactData.content.form.fields.email.regexError) }}</small>
                      </div>
                      <Recaptcha sitekey="6LdTuKUqAAAAAMpwpPsRWAxqACH6wZT4oQDQoMC2"
                                 class="recaptcha123">
                        <Button type="submit"
                                class="pb-2 submit-email mt-1"
                                @click="handleSubmit(!v$.value.$invalid)">{{ contactData.content.pageLabels.form.button
                          }}</Button>
                      </Recaptcha>
                    </form>
                    <!-- <Recaptcha ref="recaptchaRef" /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer class="xl:absolute xl:bottom-0 xl:w-full" />
</section>