import { useStore } from 'vuex';
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'Header',

  components: {
  },
  setup: () => {
    const store = useStore();
    const router = useRouter();

    /** sidebar dynamic hiding logic */
    const sidebarChecked = ref(false);
    const displayButton = ref(false);
    const resize = () => {
      sidebarChecked.value = false;
      const cachedDisplayValue = displayButton.value;
      displayButton.value = innerWidth < 1200;
      if (cachedDisplayValue !== displayButton.value) {
        const device = displayButton.value ? 'mobile' : 'desktop';
        store.dispatch('setDevice', device);
      }
    };
    window.addEventListener('resize', resize);

    const imgsrc = computed(() => store.getters.mainData.header.logo.image);
    const navMenu = computed(() => store.getters.mainData.header.menu);
    const languageMenu = computed(() => store.getters.mainData.header.languageMenu);
    const toHome = computed(() => store.getters.mainData.header.menu[0].url || '');

    const selectedLanguage = computed({
      get: () => languageMenu.value.find(lang => lang.label.toLowerCase() === store.getters.language),
      set: (newValue) => { store.dispatch('setLanguage', newValue); }
    });

    const fetchHeaderData = (language) => {
      fetch(`https://${store.getters.mainData.config.baseUrl}/${language}`)
        .then(res => res.json())
        .then(d => {
          store.dispatch('setLanguage', language);
          store.dispatch('setMainData', d.data);

          setTimeout(() => {
            window.cookieconsent?.run({
              notice_banner_type: 'simple',
              consent_type: 'implied',
              palette: 'light',
              language: d.data.config.cookieConsent.language,
              page_load_consent_levels: ['strictly-necessary',
                'functionality',
                'tracking',
                'targeting'],
              notice_banner_reject_button_hide: false,
              preferences_center_close_button_hide: false,
              page_refresh_confirmation_buttons: false,
              website_name: d.data.config.cookieConsent.websiteName,
              website_privacy_policy_url: d.data.config.cookieConsent.privacyPolicyUrl
            });
          }, 1000);

          if (!store.getters.metaTagsSet) {
            // console.log('adding meta tags', d.data.config.metaTags);
            const metaTags = d.data.config.metaTags;
            const head = document.head;
            const metaElements = metaTags.split('\n').map(metaTag => {
              const template = document.createElement('template');
              template.innerHTML = metaTag.trim();
              return template.content.firstChild;
            });
            metaElements.forEach(metaElement => head.appendChild(metaElement));
            store.dispatch('setMetaTagsSet', true);
          }

          try {
            window.document.querySelector('link[rel="icon"]').href = d.data.header.favicon.image;
            window.document.title = d.data.header.title;
          } catch (error) {
            console.error('Error setting favicon', error);
          }

          /** '/' redirects to '/sr', so we need to set to e.g '/en' on language change */
          store.dispatch('setHomeRouterLinkUrl', `/${language}`);

          return Promise.resolve(d.data);
        }).catch(_err => {
          // console.verbose(err);
          return [];
        });
    };

    const onLanguageChange = (event) => {
      const language = event.value.label.toLowerCase();
      store.dispatch('setLanguage', language);

      let newHref = window.location.href;

      // Check if URL already includes a language segment
      const hrefHasLanguage = /\/(sr|en|de)/.test(window.location.href);

      // If URL does not include a language, append a placeholder to be replaced
      if (!hrefHasLanguage) {
        newHref += '/replace';
      }

      // console.log('what the hell is the newhref', newHref);

      // Replace the language segment in the URL or the placeholder with the selected language
      newHref = newHref.replace(/(\/)+(replace|sr|en|de)/, `/${language}`);

      // Update the URL if it has changed
      if (newHref !== window.location.href) {
        window.location.href = newHref;
      }
    };

    const onSidebarLanguageChange = (event, menuItem) => {
      const language = menuItem.label.toLowerCase();
      store.dispatch('setLanguage', language);
      sidebarChecked.value = false;
      goToNewLang(language);
    };

    const goToNewLang = (lang) => {
      // const newRoute = router.currentRoute.value.path;

      const language = lang.toLowerCase();

      // let a = newRoute.replace('/sr', `/${language}`);
      // a = a.replace('/en', `/${language}`);
      // a = a.replace('/de', `/${language}`);

      // if (a === router.currentRoute.value.path) {
      // a = a + '' + language;
      // }

      // console.log('this is a', a);

      // router.push(a);

      const x = window.location.href.replace('/sr', `/${language}`).replace('/en', `/${language}`).replace('/de', `/${language}`);
      if (x === window.location.href) {
        const y = `${window.location.href}${language}`;
        window.location.href = y;
      } else {
        window.location.href = x;
      }
    };

    const headerSessionResolved = ref(true);

    onMounted(() => {
      setTimeout(async () => {
        store.dispatch('setLanguage', selectedLanguage.value?.label?.toLowerCase() || store.getters.language);
        fetchHeaderData(selectedLanguage.value?.label?.toLowerCase() || store.getters.language);
        headerSessionResolved.value = true;
      }, 100);
    });

    return {
      displayButton,
      sidebarChecked,
      selectedLanguage,
      languageMenu,
      toHome,
      imgsrc,
      navMenu,
      headerSessionResolved,
      onLanguageChange,
      onSidebarLanguageChange,
      goToNewLang
    };
  }
};
