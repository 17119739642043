<section class="home"
         ref="pageTop"
         v-if="homeSessionResolved">
  <Header />
  <div class="home">
    <div class="main flex justify-content-center flex-wrap mb-5">
      <div class="col-12 text-center headline">
        <h1>{{ homeData.content.top.headline.label }}</h1>
        <p class="subtitle mt-0 xl:mt-1">{{ homeData.content.top.subtitle.label }}</p>
      </div>
      <!-- Flight Search -->
      <div class="dynwid col-12 p-0 flight-search">
        <FlightSearch :fields-to-validate="fieldsToValidate"
                      :flight-tab-data="homeData.content.searchTabs.flight.form"></FlightSearch>
      </div>
    </div>
    <!-- Services -->
    <div class="services mt-8 pb-6">
      <h2 class="text-center pt-5">{{ homeData.content.services.headline }}</h2>
      <div class="flex justify-content-center pt-3 pb-5">
        <div class="col-12 dynwid">
          <div class="grid">
            <div class="service col-12 lg:col-4 text-center"
                 v-for="service in homeData.content.services.list">
              <img class="service-image"
                   :src="service.image.url"
                   :alt="service.image.alt">
              <h2 class="service-title">{{ service.title }}</h2>
              <div class="service-text" v-html="service.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Special Offers -->
    <!-- <div class="special-offers">
      <h1 class="text-center mt-5 pt-5 mb-5 pb-5 ">{{ homeData.content.offers.headline }}</h1>
      <div class="flex overflow-auto justify-content-start lg:justify-content-center pb-5 mb-5 px-3">
        <div class="dynwid flex">
          <div class="col-10 xl:col-3" v-for="offer in homeData.content.offers.list">
            <div class="p-0 special-offer">
              <Card class="shadow-4" @click="populateFlightQueryFromOffer(offer)">
                <template #header>
                  <img class="offer-header-img" :src="offer.image.url" alt="offer.image.alt">
                  <Card class="price-cardlet">
                    <template #content class="p-0">
                      <span class="text-white">{{ offer.label }}</span>
                    </template>
                  </Card>
                </template>
                <template #content>
                  <div class="offer-title mt-2 mb-0">{{ offer.city }}</div>
                  <div class="subtitle offer-subtitle mb-2">{{ offer.country }}</div>
                </template>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <Footer class="xl:absolute xl:bottom-0 xl:w-full" /> -->
  <Footer />
</section>