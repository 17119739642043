import axios from 'axios';

// GET ENDPOINTS

// Homepage
export const getHomepage = async (lang, store, router) => {
  let data;
  try {
    const response = await fetch(`https://${store.getters.mainData.config.baseUrl}/${lang}`);
    data = await response.json();

    if (data.status === 'failure') {
      store.dispatch('setLanguage', data.data.error.showMessage.redirectUrl.split('/')[1]);
      const res = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}`);
      const dat = await res.json();
      store.dispatch('setMainData', dat.data);
      store.dispatch('setLoader', dat.data.loader);
      const returnValue = Promise.resolve(dat.data);
      // console.log('returning', returnValue);
      return returnValue;
    }

    // console.log('setting loader', data.data.loader);
    store.dispatch('setLoader', data.data.loader);
    store.dispatch('setLanguage', data.data.config.activeLanguage);
    store.dispatch('setMainData', data.data);

    if ('loader' in data.data) {
      store.dispatch('setLoader', data.data.loader);
    }
    return Promise.resolve(data.data);
  } catch (error) {
    console.error(error);
    return null;
  } finally {
    if (data.data.error) {
      router.push(data.data.error.showMessage.redirectUrl);
    }
  }
};

// About
export const getAbout = async (lang, about) => {
  const response = await axios.default.get(`https://putnik-backend.ibe-servers.com/${lang}/about/${about}`);
  return response.data;
};

export const postSearch = async (url, payload) => {
  try {
    const response = await axios.post(url, payload);
    // console.log('Data:', response.data);
    // console.log('Status:', response.status);
  } catch (error) {
    console.error('Error:', error);
  }
};
