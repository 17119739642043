<script src="./PnrPage.js"></script>

<template>
  <section class="pnr-num-cl-0 pnr-page"
           v-if="pnrSessionResolved">
    <Header />
    <div class="pnr-num-cl-1 flex justify-content-center mt-3 xl:mt-5">
      <div class="pnr-num-cl-2 dynwid px-2">
        <div class="pnr-num-cl-3 breadcrumb">
          <Breadcrumb :home="breadcrumbs.home"
                      :model="breadcrumbs.items">
            <template #item="{ item }">
              <a style="height: 30px; padding-left: 6px; padding-right: 6px;"
                 :href="item.url"
                 :class="{ active: item.state == 'current', 'disabled': item.state == 'next' }"
                 class="pnr-num-cl-4 breadcrumb-item flex justify-content-center align-items-center">
                <div class="pnr-num-cl-5 flex">
                  <img v-if="item.icon"
                       :src="item.icon"
                       class="pnr-num-cl-6 home-icon">
                  <div class="pnr-num-cl-7 flex"
                       v-if="!item.icon">
                    <div>
                      {{ item.number }}{{ '.' }}
                    </div>
                    <div class="pnr-num-cl-8 hidden md:block">
                      {{ ' ' }}{{ item.label }}
                    </div>
                    <div class="pnr-num-cl-9 block md:hidden"
                         v-if="item.state === 'current'">
                      {{ ' ' }}{{ item.label }}
                    </div>
                  </div>
                </div>
              </a>
            </template>
          </Breadcrumb>
        </div>
        <h2 class="pnr-num-cl-10 font-semibold mt-5 mb-2">
          {{ pnrData.content.pageLabels.headline }}
        </h2>
      </div>
    </div>
    <div class="pnr-num-cl-11 flex gray justify-content-center mt-1">
      <div class="pnr-num-cl-12 dynwid">
        <div class="pnr-num-cl-13 grid pt-6 pb-6"
             id="main-container">
          <div class="pnr-num-cl-14 col-12">
            <Card class="pnr-num-cl-15 main-card border-1 border-gray-300">
              <template #header>
                <div class="pnr-num-cl-16 p-3 text-xl border-bottom-1 border-gray-300 flex align-items-center">
                  <img :src="pnrData.content.pnr.image"
                       alt=""
                       class="pnr-num-cl-17 pnr-checkmark">
                  <div class="pnr-num-cl-18 pl-3">
                    {{ pnrData.content.pageLabels.pnr.controlNumber }}
                  </div>
                  <div class="pnr-num-cl-19 pl-2 font-semibold">
                    {{ pnrData.content.pnr.controlNumber }}
                  </div>
                </div>
              </template>
              <template #content>
                <div class="pnr-num-cl-20 p-3 border-bottom-1 border-gray-300">
                  <div v-for="subtitle in pnrData.content.pageLabels.subtitles"
                       :key="subtitle">
                    {{ subtitle }}
                  </div>
                </div>
                <div id="outbound">
                  <div class="pnr-num-cl-21 text-xl p-3 border-bottom-1 border-gray-300 font-semibold">
                    {{ pnrData.content.pageLabels.flightDetails.outbound }}
                  </div>
                  <div v-for="(segment, index) in pnrData.content.flightDetails.outbound.segments"
                       :key="`${segment.marketingAirline}${segment.flightNumber}`">
                    <div v-if="index > 0"
                         class="pnr-num-cl-22 p-2 border-y-1 border-gray-300 bg-gray-100">
                      Presedanje u {{ pnrData.content.airports[segment.departure.code].city }} ({{
                        segment.departure.code }}) {{ '  ' }} {{ segment.stopDuration }}
                    </div>
                    <div class="pnr-num-cl-23 flex flex-wrap align-items-center">
                      <div class="pnr-num-cl-24 p-3 w-12 md:w-3 border-bottom-1 md:border-bottom-none border-gray-300">
                        <div>
                          <img :src="pnrData.content.airlines[segment.marketingAirline].image"
                               :alt="segment.marketingAirline">
                        </div>
                        <div>
                          <span class="pnr-num-cl-25 font-semibold">
                            {{ pnrData.content.pageLabels.flightDetails.flightNumber }}
                            :
                          </span>
                          <span>
                            {{ segment.marketingAirline }}-{{ segment.flightNumber }}
                          </span>
                        </div>
                        <div>
                          <span class="pnr-num-cl-26 font-semibold">
                            {{ pnrData.content.pageLabels.email.duration }}:
                          </span>
                          <span>
                            {{ segment.duration }}
                          </span>
                        </div>
                      </div>
                      <div class="pnr-num-cl-27 w-12 md:w-9 flex flex-wrap">
                        <div class="pnr-num-cl-28 border-left-0 md:border-left-1 border-gray-300 p-3 w-12 md:w-6">
                          <div>
                            <div class="pnr-num-cl-29 block md:hidden">
                              {{ pnrData.content.pageLabels.flightDetails.takingOff }}
                            </div>
                            <div class="pnr-num-cl-30 text-xl font-semibold">
                              {{ segment.departure.code }}
                            </div>
                            <div class="pnr-num-cl-31 border-gray-400 border-bottom-1 pb-2">
                              {{ pnrData.content.airports[segment.departure.code].city }}
                              {{ pnrData.content.airports[segment.departure.code].airport }}
                              ({{ segment.departure.code }})
                            </div>
                          </div>
                          <div class="pnr-num-cl-32 pt-2">
                            <span class="pnr-num-cl-33 font-semibold"> {{ pnrData.content.pageLabels.email.time }}:
                            </span>
                            <span>
                              {{ segment.departure.time }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-34 font-semibold"> {{ pnrData.content.pageLabels.email.date }}:
                            </span>
                            <span>
                              {{ segment.departure.date }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-35 font-semibold"> {{ pnrData.content.pageLabels.email.terminal }}:
                            </span>
                            <span>
                              {{ segment.departure.terminal }}
                            </span>
                          </div>
                        </div>
                        <div class="pnr-num-cl-36 border-left-0 md:border-left-1 border-gray-300 p-3 w-12 md:w-6">
                          <div class="pnr-num-cl-37 block md:hidden">
                            {{ pnrData.content.pageLabels.flightDetails.landing }}
                          </div>
                          <div class="pnr-num-cl-38 text-xl font-semibold">
                            {{ segment.arrival.code }}
                          </div>
                          <div class="pnr-num-cl-39 border-gray-400 border-bottom-1 pb-2">
                            {{ pnrData.content.airports[segment.arrival.code].city }}
                            {{ pnrData.content.airports[segment.arrival.code].airport }}
                            ({{ segment.arrival.code }})
                          </div>
                          <div class="pnr-num-cl-40 pt-2">
                            <span class="pnr-num-cl-41 font-semibold">
                              {{ pnrData.content.pageLabels.email.time }}:
                            </span>
                            <span>
                              {{ segment.arrival.time }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-42 font-semibold">
                              {{ pnrData.content.pageLabels.email.date }}:
                            </span>
                            <span>
                              {{ segment.arrival.date }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-43 font-semibold">
                              {{ pnrData.content.pageLabels.email.terminal }}:
                            </span>
                            <span>
                              {{ segment.arrival.terminal }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="inbound"
                     v-if="pnrData.content.flightDetails.inbound">
                  <div class="pnr-num-cl-44 text-xl p-3 font-semibold border-y-1 border-gray-300">
                    {{ pnrData.content.pageLabels.flightDetails.inbound }}
                  </div>
                  <div v-for="(segment, index) in pnrData.content.flightDetails.inbound.segments"
                       :key="`${segment.marketingAirline}${segment.flightNumber}`">
                    <div v-if="index > 0"
                         class="pnr-num-cl-45 p-2 border-y-1 border-gray-300 bg-gray-100">
                      {{ `${pnrData.content.pageLabels.email.stop}
                      ${pnrData.content.airports[segment.departure.code].city} ( ${segment.departure.code} )
                      ${segment.stopDuration}` }}
                    </div>
                    <div class="pnr-num-cl-46 flex flex-wrap align-items-center">
                      <div class="pnr-num-cl-47 p-3 w-12 md:w-3 border-bottom-1 md:border-bottom-none border-gray-300">
                        <div>
                          <img :src="pnrData.content.airlines[segment.marketingAirline].image"
                               :alt="segment.marketingAirline">
                        </div>
                        <div>
                          <span class="pnr-num-cl-48 font-semibold">
                            {{ pnrData.content.pageLabels.flightDetails.flightNumber }}
                            :
                          </span>
                          <span>
                            {{ segment.marketingAirline }}-{{ segment.flightNumber }}
                          </span>
                        </div>
                        <div>
                          <span class="pnr-num-cl-49 font-semibold">
                            {{ pnrData.content.pageLabels.email.duration }}:
                          </span>
                          <span>
                            {{ segment.duration }}
                          </span>
                        </div>
                      </div>
                      <div class="pnr-num-cl-50 w-12 md:w-9 flex flex-wrap">
                        <div class="pnr-num-cl-51 border-left-0 md:border-left-1 border-gray-300 p-3 w-12 md:w-6">
                          <div>
                            <div class="pnr-num-cl-52 block md:hidden">
                              {{ pnrData.content.pageLabels.flightDetails.takingOff }}
                            </div>
                            <div class="pnr-num-cl-53 text-xl font-semibold">
                              {{ segment.departure.code }}
                            </div>
                            <div class="pnr-num-cl-54 border-gray-400 border-bottom-1 pb-2">
                              {{ pnrData.content.airports[segment.departure.code].city }}
                              {{ pnrData.content.airports[segment.departure.code].airport }}
                              ({{ segment.departure.code }})
                            </div>
                          </div>
                          <div class="pnr-num-cl-55 pt-2">
                            <span class="pnr-num-cl-56 font-semibold">
                              {{ pnrData.content.pageLabels.email.time }}:
                            </span>
                            <span>
                              {{ segment.departure.time }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-57 font-semibold">
                              {{ pnrData.content.pageLabels.email.date }}:
                            </span>
                            <span>
                              {{ segment.departure.date }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-58 font-semibold">
                              {{ pnrData.content.pageLabels.email.terminal }}:
                            </span>
                            <span>
                              {{ segment.departure.terminal }}
                            </span>
                          </div>
                        </div>
                        <div class="pnr-num-cl-59 border-left-0 md:border-left-1 border-gray-300 p-3 w-12 md:w-6">
                          <div class="pnr-num-cl-60 block md:hidden">
                            {{ pnrData.content.pageLabels.flightDetails.landing }}
                          </div>
                          <div class="pnr-num-cl-61 text-xl font-semibold">
                            {{ segment.arrival.code }}
                          </div>
                          <div class="pnr-num-cl-62 border-gray-400 border-bottom-1 pb-2">
                            {{ pnrData.content.airports[segment.arrival.code].city }}
                            {{ pnrData.content.airports[segment.arrival.code].airport }}
                            ({{ segment.arrival.code }})
                          </div>
                          <div class="pnr-num-cl-63 pt-2">
                            <span class="pnr-num-cl-64 font-semibold">
                              {{ pnrData.content.pageLabels.email.time }}:
                            </span>
                            <span>
                              {{ segment.arrival.time }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-65 font-semibold">
                              {{ pnrData.content.pageLabels.email.date }}:
                            </span>
                            <span>
                              {{ segment.arrival.date }}
                            </span>
                          </div>
                          <div>
                            <span class="pnr-num-cl-66 font-semibold">
                              {{ pnrData.content.pageLabels.email.terminal }}:
                            </span>
                            <span>
                              {{ segment.arrival.terminal }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="travelers">
                  <div class="pnr-num-cl-67 text-xl p-3 font-semibold border-y-1 border-gray-300">
                    {{ pnrData.content.pageLabels.travelers.headline }}
                  </div>
                  <div class="pnr-num-cl-68 p-3 hidden md:block">
                    <table class="pnr-num-cl-69 w-12"
                           cellpadding="0"
                           cellspacing="0">
                      <tr class="pnr-num-cl-70 bg-gray-300">
                        <td class="pnr-num-cl-71 p-1 border-bottom-1 border-gray-300">
                          <span class="pnr-num-cl-72 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.gender.title }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-73 p-1 border-bottom-1 border-gray-300">
                          <span class="pnr-num-cl-74 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.firstName }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-75 p-1 border-bottom-1 border-gray-300">
                          <span class="pnr-num-cl-76 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.lastName }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-77 p-1 border-bottom-1 border-gray-300">
                          <span class="pnr-num-cl-78 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.type }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-79 p-1 border-bottom-1 border-gray-300">
                          <span class="pnr-num-cl-80 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.birthdate }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-81 p-1 border-bottom-1 border-gray-300">
                          <span class="pnr-num-cl-82 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.price }}
                          </span>
                        </td>
                      </tr>
                      <!-- BEGIN LOOP -->
                      <template v-for="(ageGroup, key) in pnrData.content.travelers"
                                :key="ageGroup">
                        <tr v-for="passenger in ageGroup"
                            :key="`${ageGroup}${passenger.firstName}${passenger.lastName}`">
                          <td class="pnr-num-cl-83 p-1 border-bottom-1 border-gray-300">
                            {{ pnrData.content.pageLabels.email.travelers.gender[passenger.gender] }}
                          </td>
                          <td class="pnr-num-cl-84 p-1 border-bottom-1 border-gray-300">
                            {{ pnrData.content.pageLabels.email.travelers.title[passenger.title] }}{{pnrData.content.pageLabels.email.travelers.title[passenger.title] && ' '}}{{ passenger.firstName }}
                          </td>
                          <td class="pnr-num-cl-85 p-1 border-bottom-1 border-gray-300">
                            {{ passenger.lastName }}
                          </td>
                          <td class="pnr-num-cl-86 p-1 border-bottom-1 border-gray-300">
                            {{ pnrData.content.pageLabels.travelers[key] }}
                          </td>
                          <td class="pnr-num-cl-87 p-1 border-bottom-1 border-gray-300">
                            {{ passenger.birthdate }}
                          </td>
                          <td class="pnr-num-cl-88 p-1 border-bottom-1 border-gray-300">
                            <FormattedCurrency :currency="passenger.fare.currency"
                                               :amount="passenger.fare.total"
                                               :currencyPosition="passenger.fare.currencyPosition" />
                          </td>
                        </tr>
                      </template>
                      <!-- END LOOP -->
                      <tr v-if="pnrData.content.formOfPayment.type == 'swb'">
                        <td class="pnr-num-cl-89 p-1"
                            colspan="5">
                          <span class="pnr-num-cl-90 font-semibold">
                            {{ pnrData.content.pageLabels.travelers.serviceFee }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-91 p-1">
                          <span class="pnr-num-cl-92 font-semibold">
                            <FormattedCurrency :currency="pnrData.content.totalPrice.currency"
                                               :amount="pnrData.content.totalPrice.serviceFee"
                                               :currencyPosition="pnrData.content.totalPrice.currencyPosition" />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pnr-num-cl-93 p-1"
                            colspan="5">
                          <span class="pnr-num-cl-94 font-semibold">
                            {{ pnrData.content.pageLabels.travelers.totalPrice }}
                          </span>
                        </td>
                        <td class="pnr-num-cl-95 p-1">
                          <span class="pnr-num-cl-96 font-semibold">
                            <FormattedCurrency :currency="pnrData.content.totalPrice.currency"
                                               :amount="pnrData.content.totalPrice.amount"
                                               :currencyPosition="pnrData.content.totalPrice.currencyPosition" />
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="pnr-num-cl-97 p-3 block md:hidden">
                    <!-- BEGIN LOOP -->
                    <template v-for="(ageGroup, key) in pnrData.content.travelers"
                              :key="ageGroup">
                      <!-- <div class="pnr-num-cl-98 border-gray-300 border-1 border-rounded my-2" v-for="(passenger, index) in ageGroup" :key="`${ageGroup}${passenger.firstName}${passenger.lastName}`" :class="{ 'border-top-none': key == 'ADT' && index == 0, 'border-top-1': key != 'ADT' || index != 0 }"> -->
                      <div class="pnr-num-cl-99 border-gray-300 border-bottom-1 my-2 p-2 pt-0 relative"
                           v-for="(passenger, index) in ageGroup"
                           :key="`${index}${ageGroup}${passenger.firstName}${passenger.lastName}`">
                        <i class="pnr-num-cl-100 pi pi-user text-gray-400 absolute top-0 right-0 pt-2 pr-2 z-10">
                        </i>
                        <div>
                          <span class="pnr-num-cl-101 font-semibold">
                            {{ pnrData.content.pageLabels.buyer.title }}:
                          </span> {{ pnrData.content.pageLabels.email.travelers.title[passenger.title] }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-101 font-semibold">
                            {{ pnrData.content.pageLabels.buyer.gender }}:
                          </span> {{ pnrData.content.pageLabels.email.travelers.gender[passenger.gender] }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-102 font-semibold">
                            {{ pnrData.content.pageLabels.buyer.firstName }}:
                          </span> {{ passenger.firstName }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-103 font-semibold">
                            {{ pnrData.content.pageLabels.buyer.lastName }}:
                          </span> {{ passenger.lastName }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-104 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.type }}:
                          </span> {{ pnrData.content.pageLabels.travelers[key] }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-105 font-semibold">
                            {{ pnrData.content.pageLabels.email.date }}:
                          </span> {{ passenger.birthdate }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-106 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.price }}:
                            <FormattedCurrency :currency="passenger.fare.currency"
                                               :amount="passenger.fare.total"
                                               :currencyPosition="passenger.fare.currencyPosition" />
                          </span>
                        </div>
                      </div>
                    </template>
                    <!-- END LOOP -->
                    <div class="pnr-num-cl-107 flex justify-content-between my-1">
                      <div>
                        <span class="pnr-num-cl-108 font-semibold pl-2">
                          {{ pnrData.content.pageLabels.travelers.serviceFee }}
                        </span>
                      </div>
                      <div class="pnr-num-cl-109 font-semibold pr-2">
                        <FormattedCurrency :currency="pnrData.content.totalPrice.currency"
                                           :amount="pnrData.content.totalPrice.serviceFee"
                                           :currencyPosition="pnrData.content.totalPrice.currencyPosition" />
                      </div>
                    </div>
                    <div class="pnr-num-cl-110 flex justify-content-between">
                      <div>
                        <span class="pnr-num-cl-111 font-semibold pl-2">
                          {{ pnrData.content.pageLabels.travelers.totalPrice }}
                        </span>
                      </div>
                      <div class="pnr-num-cl-112 font-semibold pr-2">
                        <FormattedCurrency :currency="pnrData.content.totalPrice.currency"
                                           :amount="pnrData.content.totalPrice.amount"
                                           :currencyPosition="pnrData.content.totalPrice.currencyPosition" />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="docs"
                     v-if="pnrData.content.documents">
                  <div class="pnr-num-cl-113 text-xl p-3 font-semibold border-y-1 border-gray-300">
                    {{ pnrData.content.pageLabels.travelers.documents.headline }}
                  </div>
                  <div class="pnr-num-cl-114 p-3 hidden md:block">
                    <table class="pnr-num-cl-115 w-12"
                           cellpadding="0"
                           cellspacing="0">
                      <tbody>
                        <tr class="pnr-num-cl-116 bg-gray-200 border-bottom-1 border-gray-300">
                          <td class="pnr-num-cl-117 p-1 border-bottom-1 border-gray-300">
                            <span class="pnr-num-cl-118 font-semibold">
                              {{ pnrData.content.pageLabels.email.travelers.firstName }}
                            </span>
                          </td>
                          <td class="pnr-num-cl-119 p-1 border-bottom-1 border-gray-300">
                            <span class="pnr-num-cl-120 font-semibold">
                              {{ pnrData.content.pageLabels.email.travelers.lastName }}
                            </span>
                          </td>
                          <td class="pnr-num-cl-121 p-1 border-bottom-1 border-gray-300">
                            <span class="pnr-num-cl-122 font-semibold">
                              {{ pnrData.content.pageLabels.email.travelers.documents.passport.number }}
                            </span>
                          </td>
                          <td class="pnr-num-cl-123 p-1 border-bottom-1 border-gray-300">
                            <span class="pnr-num-cl-124 font-semibold">
                              {{ pnrData.content.pageLabels.email.travelers.documents.passport.passportCountry }}
                            </span>
                          </td>
                          <td class="pnr-num-cl-125 p-1 border-bottom-1 border-gray-300">
                            <span class="pnr-num-cl-126 font-semibold">
                              {{ pnrData.content.pageLabels.email.travelers.documents.passport.citizenship }}
                            </span>
                          </td>
                          <td class="pnr-num-cl-127 p-1 border-bottom-1 border-gray-300">
                            <span class="pnr-num-cl-128 font-semibold">
                              {{ pnrData.content.pageLabels.email.travelers.documents.passport.expireDate }}
                            </span>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in pnrData.content.travelers.ADT"
                            :key="index">
                          <td class="pnr-num-cl-129 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.travelers.CHD && !pnrData.content.INF && index !== pnrData.content.travelers.ADT?.length - 1 }">
                            {{ row.firstName }}
                          </td>
                          <td class="pnr-num-cl-130 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.travelers.CHD && !pnrData.content.INF && index !== pnrData.content.travelers.ADT?.length - 1 }">
                            {{ row.lastName }}
                          </td>
                          <td class="pnr-num-cl-131 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.travelers.CHD && !pnrData.content.INF && index !== pnrData.content.travelers.ADT?.length - 1 }">
                            {{ row.passport.number }}
                          </td>
                          <td class="pnr-num-cl-132 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.travelers.CHD && !pnrData.content.INF && index !== pnrData.content.travelers.ADT?.length - 1 }">
                            {{ row.passport.passportCountry }}
                          </td>
                          <td class="pnr-num-cl-133 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.travelers.CHD && !pnrData.content.INF && index !== pnrData.content.travelers.ADT?.length - 1 }">
                            {{ row.passport.citizenship }}
                          </td>
                          <td class="pnr-num-cl-134 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.travelers.CHD && !pnrData.content.INF && index !== pnrData.content.travelers.ADT?.length - 1 }">
                            {{ row.passport.expireDate }}
                          </td>
                        </tr>
                        <tr v-for="(row, index) in pnrData.content.travelers.CHD"
                            :key="index">
                          <td class="pnr-num-cl-135 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.INF && index !== pnrData.content.travelers.CHD?.length - 1 }">
                            {{ row.firstName }}
                          </td>
                          <td class="pnr-num-cl-136 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.INF && index !== pnrData.content.travelers.CHD?.length - 1 }">
                            {{ row.lastName }}
                          </td>
                          <td class="pnr-num-cl-137 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.INF && index !== pnrData.content.travelers.CHD?.length - 1 }">
                            {{ row.passport.number }}
                          </td>
                          <td class="pnr-num-cl-138 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.INF && index !== pnrData.content.travelers.CHD?.length - 1 }">
                            {{ row.passport.passportCountry }}
                          </td>
                          <td class="pnr-num-cl-139 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.INF && index !== pnrData.content.travelers.CHD?.length - 1 }">
                            {{ row.passport.citizenship }}
                          </td>
                          <td class="pnr-num-cl-140 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': !pnrData.content.INF && index !== pnrData.content.travelers.CHD?.length - 1 }">
                            {{ row.passport.expireDate }}
                          </td>
                        </tr>
                        <tr v-for="(row, index) in pnrData.content.travelers.INF"
                            :key="index">
                          <td class="pnr-num-cl-141 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': index !== pnrData.content.travelers.INF?.length - 1 }">
                            {{ row.firstName }}
                          </td>
                          <td class="pnr-num-cl-142 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': index !== pnrData.content.travelers.INF?.length - 1 }">
                            {{ row.lastName }}
                          </td>
                          <td class="pnr-num-cl-143 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': index !== pnrData.content.travelers.INF?.length - 1 }">
                            {{ row.passport.number }}
                          </td>
                          <td class="pnr-num-cl-144 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': index !== pnrData.content.travelers.INF?.length - 1 }">
                            {{ row.passport.passportCountry }}
                          </td>
                          <td class="pnr-num-cl-145 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': index !== pnrData.content.travelers.INF?.length - 1 }">
                            {{ row.passport.citizenship }}
                          </td>
                          <td class="pnr-num-cl-146 p-1"
                              :class="{ 'border-bottom-1 border-gray-300': index !== pnrData.content.travelers.INF?.length - 1 }">
                            {{ row.passport.expireDate }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="pnr-num-cl-147 p-3 block md:hidden">
                    <template v-for="(ageGroup, key) in pnrData.content.travelers"
                              :key="`${ageGroup}${key}`">
                      <!-- <div class="pnr-num-cl-148 border-gray-300 border-bottom-1 my-2 p-2 pt-0 relative" v-for="(row, index) in ageGroup" :key="index" :class="{ 'border-top-none': key == 'ADT' && index == 0, 'border-top-1': key != 'ADT' || index != 0 }"> -->
                      <div class="pnr-num-cl-149 border-gray-300 border-bottom-1 my-2 p-2 pt-0 relative"
                           v-for="(row, index) in ageGroup"
                           :key="index">
                        <i class="pnr-num-cl-150 pi pi-id-card text-gray-400 absolute top-0 right-0 pt-2 pr-2 z-10">
                        </i>
                        <div>
                          <span class="pnr-num-cl-151 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.firstName }}:
                          </span> {{ row.firstName }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-152 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.lastName }}:
                          </span> {{ row.lastName }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-153 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.documents.passport.number }}:
                          </span> {{ row.passport.number }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-154 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.documents.passport.passportCountry }}:
                          </span> {{ row.passport.passportCountry }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-155 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.documents.passport.citizenship }}:
                          </span> {{ row.passport.citizenship }}
                        </div>
                        <div>
                          <span class="pnr-num-cl-156 font-semibold">
                            {{ pnrData.content.pageLabels.email.travelers.documents.passport.expireDate }}:
                          </span> {{ row.passport.expireDate }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="pnr-num-cl-157 tarrif-and-baggage flex flex-wrap">
                  <div id="tariff"
                       v-if="pnrData.content.priceClasses.display"
                       class="pnr-num-cl-158 w-12 md:w-3">
                    <div class="pnr-num-cl-159 text-xl p-3 font-semibold border-y-1 border-gray-300">
                      {{ pnrData.content.pageLabels.priceClasses.headline }}
                    </div>
                    <div class="pnr-num-cl-160 p-3">
                      <div class="pnr-num-cl-161 flex flex-wrap price-classes">
                        <!-- outboundinbound -->
                        <div class="pnr-num-cl-162 col-12 p-0">
                          <div class="pnr-num-cl-163 card-content-inside flex flex-wrap align-content-between h-full">
                            <div v-if="pnrData.content.priceClasses
                              && (
                                pnrData.content.priceClasses.display.includes('outbound')
                                || pnrData.content.priceClasses.display.includes('joined')
                                || pnrData.content.priceClasses.display.includes('separated')
                              )">
                              <div class="pdp-num-cl-261 font-sm pt-1">
                                <div class="w-12">
                                  <div v-if="pnrData.content.priceClasses.display.includes('outbound')">
                                    {{
                                      pnrData.content.pageLabels.priceClasses.display.outbound }}</div>
                                  <div v-if="pnrData.content.priceClasses.display.includes('separated')">
                                    {{
                                      pnrData.content.pageLabels.priceClasses.display.outbound }}</div>
                                  <div v-if="pnrData.content.priceClasses.display.includes('joined')">
                                    {{
                                      pnrData.content.pageLabels.priceClasses.display.joined }}</div>
                                  <div class="font-semibold pb-2 pt-1">
                                    {{ pnrData.content.priceClasses.outbound &&
                                      pnrData.content.priceClasses.outbound.name }}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="pnr-num-cl-166 w-12">
                              <div class="pnr-num-cl-167 pb-2 perk-container"
                                   v-if="pnrData.content.priceClasses.display.includes('outbound') || pnrData.content.priceClasses.display.includes('joined') || pnrData.content.priceClasses.display.includes('separated')">
                                <div class="pnr-num-cl-168 w-12 p-0"
                                     v-for="(perk, index) in pnrData.content.priceClasses.outbound.items"
                                     :key="index">
                                  <div class="pnr-num-cl-169 flex align-items-center h-2rem -my-1">
                                    <div class="pnr-num-cl-170 flex align-items-center justify-content-center pr-2">
                                      <img :src="perk.image"
                                           class="pnr-num-cl-171 perks-image" />
                                    </div>
                                    <div class="pnr-num-cl-172 font-medium text-xs">
                                      <span>
                                        {{ perk.label }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pnr-num-cl-173 col-12 p-0">
                          <div class="pnr-num-cl-174 card-content-inside flex flex-wrap align-content-between h-full">
                            <div v-if="pnrData.content.priceClasses
                              && (
                                pnrData.content.priceClasses.display.includes('inbound')
                                || pnrData.content.priceClasses.display.includes('separated')
                              )">
                              <div class="pdp-num-cl-261 font-sm pt-1">
                                <div class="w-12">
                                  <div v-if="pnrData.content.priceClasses.display.includes('inbound')">
                                    {{
                                      pnrData.content.pageLabels.priceClasses.display.inbound }}</div>
                                  <div v-if="pnrData.content.priceClasses.display.includes('separated')">
                                    {{
                                      pnrData.content.pageLabels.priceClasses.display.inbound }}</div>
                                  <div class="font-semibold pb-2 pt-1">
                                    {{ pnrData.content.priceClasses.inbound &&
                                      pnrData.content.priceClasses.inbound.name }}
                                  </div>
                                </div>
                              </div>
                              <div class="pnr-num-cl-177 w-12">
                                <div class="pnr-num-cl-178 pb-2 perk-container"
                                     v-if="pnrData.content.priceClasses.display.includes('inbound') || pnrData.content.priceClasses.display.includes('separated')">
                                  <div class="pnr-num-cl-179 w-12 p-0"
                                       v-for="(perk, index) in pnrData.content.priceClasses.inbound.items"
                                       :key="index">
                                    <div class="pnr-num-cl-180 flex align-items-center h-2rem -my-1">
                                      <div class="pnr-num-cl-181 flex align-items-center justify-content-center pr-2">
                                        <img :src="perk.image"
                                             class="pnr-num-cl-182 perks-image" />
                                      </div>
                                      <div class="pnr-num-cl-183 font-medium text-xs">
                                        <span>
                                          {{ perk.label }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div v-if="pnrData.content.fareRules">
                          <Button class="bp-num-cl-457 bg-transparent bodrer-0 text-blue-400 p-0 m-0"
                                  @click="toggleFareRulesDialog">
                            {{ pnrData.content.pageLabels.fareRules }}
                          </Button>
                          <Dialog :header="pnrData.content.pageLabels.fareRules"
                                  v-model:visible="fareRulesDialogVisible"
                                  class="bp-num-cl-458 fare-rules-modal shadow-5"
                                  :modal="true">
                            <div>
                              <div v-html="pnrData.content.fareRules.replaceAll('courier', '')">
                              </div>
                            </div>
                          </Dialog>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="baggage"
                       class="pnr-num-cl-184 w-12 md:w-9">
                    <div class="pnr-num-cl-185 text-xl p-3 font-semibold border-y-1 border-gray-300">
                      {{ pnrData.content.pageLabels.baggageAllowance.headline }}
                    </div>
                    <div class="pnr-num-cl-186 p-3 border-left-0 md:border-left-1 border-none border-gray-300">
                      <div v-if="pnrData.content.baggageAllowance">
                        <div v-if="pnrData.content.baggageAllowance.outbound">
                          <div class="pnr-num-cl-187 text-lg ">
                            {{ pnrData.content.pageLabels.flightDetails.outbound }}
                          </div>
                          <div v-for="(leg, index) in pnrData.content.baggageAllowance.outbound"
                               class="pnr-num-cl-188 pt-2"
                               :class="{ 'pt-2': index === pnrData.content.baggageAllowance.outbound.length - 1 }"
                               :key="leg">
                            <div class="pnr-num-cl-189 flex">
                              <div class="pnr-num-cl-190 pr-1">
                                {{ pnrData.content.airports[leg.departure].city }}
                              </div>
                              <div class="pnr-num-cl-191 text-gray-600 pl-1">
                                {{ pnrData.content.airports[leg.departure].code }}
                              </div>
                              <div class="pnr-num-cl-192 px-1">
                                {{ ' - ' }}
                              </div>
                              <div class="pnr-num-cl-193 px-1">
                                {{ pnrData.content.airports[leg.arrival].city }}
                              </div>
                              <div class="pnr-num-cl-194 text-gray-600 pl-1">
                                {{ pnrData.content.airports[leg.arrival].code }}
                              </div>
                            </div>
                            <div class="pnr-num-cl-195 grid"
                                 v-for="(ageGroup, key) in leg.travelers"
                                 :key="ageGroup">
                              <div
                                   class="pnr-num-cl-196 col-12 md:col-2 px-0 pb-0 md:pb-2 text-center md:text-left flex align-items-end">
                                <div class="pnr-num-cl-197 line-height-1">
                                  {{ pnrData.content.pageLabels.travelers[key] }}
                                </div>
                              </div>
                              <div class="pnr-num-cl-198 col-6 md:col-5 flex align-items-end ">
                                <img :src="ageGroup.checked.image"
                                     class="pnr-num-cl-199 h-2rem" />
                                <div class="pnr-num-cl-200 line-height-1 pl-2 md:text-sm">
                                  {{ pnrData.content.pageLabels.baggageAllowance.checked[ageGroup.checked.id] }}
                                </div>
                              </div>
                              <div class="pnr-num-cl-201 col-6 md:col-5 flex align-items-end ">
                                <img :src="ageGroup.carryOn.image"
                                     class="pnr-num-cl-202 h-1rem" />
                                <div class="pnr-num-cl-203 line-height-1 pl-2 md:text-sm">
                                  {{ pnrData.content.pageLabels.baggageAllowance.carryOn[ageGroup.carryOn.id] }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="pnrData.content.baggageAllowance.inbound">
                          <div class="pnr-num-cl-204 text-lg pt-3 ">
                            {{ pnrData.content.pageLabels.flightDetails.inbound }}
                          </div>
                          <div v-for="(leg, index) in pnrData.content.baggageAllowance.inbound"
                               class="pnr-num-cl-205 pt-2"
                               :class="{ 'pt-2': index === pnrData.content.baggageAllowance.inbound.length - 1 }"
                               :key="leg">
                            <div class="pnr-num-cl-206 flex">
                              <div class="pnr-num-cl-207 pr-1">
                                {{ pnrData.content.airports[leg.departure].city }}
                              </div>
                              <div class="pnr-num-cl-208 text-gray-600 pl-1">
                                {{ pnrData.content.airports[leg.departure].code }}
                              </div>
                              <div class="pnr-num-cl-209 px-1">
                                {{ ' - ' }}
                              </div>
                              <div class="pnr-num-cl-210 px-1">
                                {{ pnrData.content.airports[leg.arrival].city }}
                              </div>
                              <div class="pnr-num-cl-211 text-gray-600 pl-1">
                                {{ pnrData.content.airports[leg.arrival].code }}
                              </div>
                            </div>
                            <div class="pnr-num-cl-212 grid"
                                 v-for="(ageGroup, key) in leg.travelers"
                                 :key="ageGroup">
                              <div
                                   class="pnr-num-cl-213 col-12 md:col-2 px-0 pb-0 md:pb-2 text-center md:text-left flex align-items-end">
                                <div class="pnr-num-cl-214 line-height-1">
                                  {{ pnrData.content.pageLabels.travelers[key] }}
                                </div>
                              </div>
                              <div class="pnr-num-cl-215 col-6 md:col-5 flex align-items-end ">
                                <img :src="ageGroup.checked.image"
                                     class="pnr-num-cl-216 h-2rem" />
                                <div class="pnr-num-cl-217 line-height-1 pl-2 md:text-sm">
                                  {{ pnrData.content.pageLabels.baggageAllowance.checked[ageGroup.checked.id] }}
                                </div>
                              </div>
                              <div class="pnr-num-cl-218 col-6 md:col-5 flex align-items-end ">
                                <img :src="ageGroup.carryOn.image"
                                     class="pnr-num-cl-219 h-1rem" />
                                <div class="pnr-num-cl-220 line-height-1 pl-2 md:text-sm">
                                  {{ pnrData.content.pageLabels.baggageAllowance.carryOn[ageGroup.carryOn.id] }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="buyer-and-payment-method"
                     class="pnr-num-cl-221 grid grid-nogutter">
                  <div class="pnr-num-cl-222 col-12 md:col-6">
                    <div class="pnr-num-cl-223 p-3 text-xl font-semibold border-y-1 border-gray-300">
                      {{ pnrData.content.pageLabels.buyer.headline }}
                    </div>
                    <div class="pnr-num-cl-224 p-3 border-right-0 md:border-right-1 border-gray-300">
                      <div v-for="key in Object.keys(pnrData.content.buyer)"
                           :key="key">
                        <div class="pnr-num-cl-225 flex py-1">
                          <div class="pnr-num-cl-226 w-5">
                            {{ pnrData.content.pageLabels.buyer[key] }}
                          </div>
                          <div class="pnr-num-cl-227 w-7 ">
                            {{ pnrData.content.buyer[key] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pnr-num-cl-228 col-12 md:col-6">
                    <div class="pnr-num-cl-229 p-3 text-xl font-semibold border-y-1 border-gray-300">
                      {{ pnrData.content.pageLabels.formOfPayment.headline }}
                    </div>
                    <div v-if="pnrData.content.formOfPayment.type == 'cc'">
                      <div class="pnr-num-cl-230 p-3">
                        <div class="pnr-num-cl-231 flex py-1">
                          <div class="pnr-num-cl-232 w-6 font-semibold">
                            {{ pnrData.content.pageLabels.formOfPayment.cc.subtitle }}
                          </div>
                        </div>
                        <div class="pnr-num-cl-233 flex py-1">
                          <div class="pnr-num-cl-234 w-6">
                            {{ pnrData.content.pageLabels.formOfPayment.cc.cardHolder }}
                          </div>
                          <div class="pnr-num-cl-235 ">
                            {{ pnrData.content.formOfPayment.cardInfo.cardHolder }}
                          </div>
                        </div>
                        <div class="pnr-num-cl-236 flex py-1">
                          <div class="pnr-num-cl-237 w-6">
                            {{ pnrData.content.pageLabels.formOfPayment.cc.number }}
                          </div>
                          <div class="pnr-num-cl-238 ">
                            {{ pnrData.content.formOfPayment.cardInfo.number }}
                          </div>
                        </div>
                        <div class="pnr-num-cl-239 flex py-1">
                          <div class="pnr-num-cl-240 w-6">
                            {{ pnrData.content.pageLabels.formOfPayment.cc.expireDate }}
                          </div>
                          <div class="pnr-num-cl-241 ">
                            {{ pnrData.content.formOfPayment.cardInfo.expireDate }}
                          </div>
                        </div>
                        <div class="pnr-num-cl-242 flex py-1">
                          <div class="pnr-num-cl-243 w-6">
                            {{ pnrData.content.pageLabels.formOfPayment.cc.cvv }}
                          </div>
                          <div class="pnr-num-cl-244 ">
                            {{ pnrData.content.formOfPayment.cardInfo.cvv }}
                          </div>
                        </div>
                        <div>
                          <div v-for="line in pnrData.content.pageLabels.formOfPayment.cc.info"
                               :key="line"
                               class="pnr-num-cl-245 py-1">
                            {{ line }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="pnrData.content.formOfPayment.type == 'inv'">
                      <div class="pnr-num-cl-246 p-3">
                        <div class="pnr-num-cl-247 font-semibold py-1">
                          {{ pnrData.content.pageLabels.formOfPayment.inv.subtitle }}
                        </div>
                        <div v-for="line in pnrData.content.pageLabels.formOfPayment.inv.info"
                             :key="line"
                             class="pnr-num-cl-248 py-1">
                          {{ line }}
                        </div>
                      </div>
                    </div>
                    <div v-if="pnrData.content.formOfPayment.type == 'twt'">
                      <div class="pnr-num-cl-246 p-3">
                        <div class="pnr-num-cl-247 font-semibold py-1">
                          {{ pnrData.content.pageLabels.formOfPayment.twt.subtitle }}
                        </div>
                        <div v-for="line in pnrData.content.pageLabels.formOfPayment.twt.info"
                             :key="line"
                             class="pnr-num-cl-248 py-1">
                          {{ line }}
                        </div>
                      </div>
                    </div>
                    <div v-if="pnrData.content.formOfPayment.type == 'swb'">
                      <div class="pnr-num-cl-249 p-3">
                        <div class="pnr-num-cl-250 mb-3">
                          <img :src="pnrData.content.pageLabels.formOfPayment.swb.image">
                        </div>
                        <div class="pnr-num-cl-251 font-semibold py-1">
                          {{ pnrData.content.pageLabels.formOfPayment.swb.subtitle }}
                        </div>
                        <div v-html="pnrData.content.pageLabels.formOfPayment.swb.info" class="pnr-num-cl-252 py-1 text-justify"></div>
                        <div>
                          <a :href="pnrData.content.pageLabels.formOfPayment.swb.link.url">{{
                            pnrData.content.pageLabels.formOfPayment.swb.link.label }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="consent"
                     class="pnr-num-cl-253 p-3 border-top-1 border-gray-300">
                  <div class="pnr-num-cl-254 py-1 flex align-items-center">
                    <img src="https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
                         class="pnr-num-cl-255 perks-image" />
                    <div class="pnr-num-cl-256 pl-2">
                      {{ pnrData.content.pageLabels.buyer.consentPassport }}
                    </div>
                  </div>
                  <div class="pnr-num-cl-257 py-1 flex">
                    <img src="https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
                         class="pnr-num-cl-258 perks-image" />
                    <div class="pnr-num-cl-259 pl-2">
                      {{ pnrData.content.pageLabels.buyer.consentVisa }}
                    </div>
                  </div>
                  <div class="pnr-num-cl-260 py-1 flex">
                    <img src="https://putnik-backend.ibe-servers.com/assets/images/general/checkmark.svg"
                         class="pnr-num-cl-261 perks-image" />
                    <div class="pnr-num-cl-262 pl-2">
                      {{ pnrData.content.pageLabels.buyer.consentTc }}
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </section>
</template>

<style src="./PnrPage.scss" lang="scss"></style>
