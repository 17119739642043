/* eslint-disable */
import { createStore } from 'vuex';
import jsonapiVuex from './jsonapi-vuex/index.js';
import { jsonapiModule } from 'jsonapi-vuex';
import initialState from './initialState';
import { reactive, ref } from 'vue';

const jvConfig = {
  cleanPatch: true,
  cleanPatchProps: ['relationships'],
  preserveJson: true,
  clearOnUpdate: true
};

export default createStore({
  namespaced: true,
  state: {
    isExpiryBeingWatched:         false,
    allFlightSearchImagesLoaded:  false,
    bookParamsEncoded:            '',
    bookSession:                  '',
    paymentSession:               '',
    bookToPay:                    false,
    comingFromSearchToBook:       false,
    createPnrParamsEncoded:       '',
    defaultHeaderData:            Object.assign(initialState.defaultData),
    departureCache:               null,
    device:                       window.innerWidth < 1200 ? 'mobile' : 'desktop',
    displayExpiredFlightModal:    false,
    expiry:                       Date.now().valueOf() + 1000 * 60 * 60 * 24 * 7,
    expiryTimeForMessage:         '',
    fieldsToValidate:             reactive({ departure: ref(), arrival: ref(), dates: ref([]) }),
    flightBookData:               Object.assign(initialState.defaultBookData),
    flightDetails:                {},
    flightResults:                Object.assign(initialState.defaultFlightResults),
    flightResultsHeader:          {},
    isDetailsModalOpen:           false,
    isDetailsSidebarOpen:         false,
    isMobileFlightSearchOpen:     false,
    language:                     'sr',
    loader:                       Object.assign(initialState.defaultLoader),
    loading:                      false,
    loadingHeadline:              '',
    loadingSubtitle:              [],
    mainData:                     Object.assign(initialState.defaultData),
    newSearches:                  0,
    oneWayFromStore:              false,
    pageLabels:                   {},
    passengerCountObject:         { ADT: 1, CHD: 0, INF: 0 },
    paymentData:                  Object.assign(initialState.defaultPaymentData),
    paymentParamsEncoded:         'x',
    pnrData:                      Object.assign(initialState.defaultPnrData),
    returnCache:                  null,
    searchSessionlessParamsEncoded:          '',
    session:                      '',
    sessionDate:                  new Date(0),
    shouldGetLoaderDataOnMounted: true,
    metaTagsSet:                  false,
    contactData:                  Object.assign(initialState.defaultContactData),
  },
  getters: {
    isExpiryBeingWatched:           (s_) => s_.isExpiryBeingWatched,
    allFlightSearchImagesLoaded:    (s_) => s_.allFlightSearchImagesLoaded,
    bookParamsEncoded:              (s_) => s_.bookParamsEncoded,
    bookSession:                    (s_) => s_.bookSession,
    paymentSession:                 (s_) => s_.paymentSession,
    bookToPay:                      (s_) => s_.bookToPay,
    comingFromSearchToBook:         (s_) => s_.comingFromSearchToBook,
    createPnrParamsEncoded:         (s_) => s_.createPnrParamsEncoded,
    departureCache:                 (s_) => s_.departureCache,
    departureCalendarLabel:         (s_) => s_.departureCalendarLabel,
    device:                         (s_) => s_.device,
    displayExpiredFlightModal:      (s_) => s_.displayExpiredFlightModal,
    expiry:                         (s_) => s_.expiry,
    expiryTimeForMessage:           (s_) => s_.expiryTimeForMessage,
    fieldsToValidate:               (s_) => s_.fieldsToValidate,
    flightBookData:                 (s_) => s_.flightBookData,
    flightDetails:                  (s_) => s_.flightDetails,
    flightResults:                  (s_) => s_.flightResults,
    flightResultsHeader:            (s_) => s_.flightResultsHeader,
    isDetailsModalOpen:             (s_) => s_.isDetailsModalOpen,
    isDetailsSidebarOpen:           (s_) => s_.isDetailsSidebarOpen,
    isMobileFlightSearchOpen:       (s_) => s_.isMobileFlightSearchOpen,
    lang:                           (s_) => s_.language,
    language:                       (s_) => s_.language,
    loader:                         (s_) => s_.loader,
    loading:                        (s_) => s_.loading,
    loadingHeadline:                (s_) => s_.loadingHeadline,
    loadingSubtitle:                (s_) => s_.loadingSubtitle,
    locale:                         (s_) => s_.language === 'sr' ? 'sr-Latn' : s_.language,
    mainData:                       (s_) => s_.mainData,
    newSearches:                    (s_) => s_.newSearches,
    oneWayFromStore:                (s_) => s_.oneWayFromStore,
    pageLabels:                     (s_) => s_.pageLabels,
    passengerCountObject:           (s_) => s_.passengerCountObject,
    paymentData:                    (s_) => s_.paymentData,
    paymentParamsEncoded:           (s_) => s_.paymentParamsEncoded,
    pnrData:                        (s_) => s_.pnrData,
    returnCache:                    (s_) => s_.returnCache,
    returnCalendarLabel:            (s_) => s_.returnCalendarLabel,
    searchSessionlessParamsEncoded: (s_) => s_.searchSessionlessParamsEncoded,
    session:                        (s_) => s_.session,
    sessionDate:                    (s_) => s_.sessionDate,
    shouldGetLoaderDataOnMounted:   (s_) => s_.shouldGetLoaderDataOnMounted,
    metaTagsSet:                    (s_) => s_.metaTagsSet,
    contactData:                    (s_) => s_.contactData
  },
  mutations: {
    setIsExpiryBeingWatched:           (s_, v_) => { s_.isExpiryBeingWatched            = v_; },
    setAllFlightSearchImagesLoaded:    (s_, v_) => { s_.allFlightSearchImagesLoaded     = v_; },
    incrementNewSearches:              (s_, v_) => { s_.newSearches                    += v_; },
    setBookParamsEncoded:              (s_, v_) => { s_.bookParamsEncoded               = v_; },
    setBookSession:                    (s_, v_) => { s_.bookSession                     = v_; },
    setPaymentSession:                 (s_, v_) => { s_.paymentSession                  = v_; },
    setBookToPay:                      (s_, v_) => { s_.bookToPay                       = v_; },
    setComingFromSearchToBook:         (s_, v_) => { s_.comingFromSearchToBook          = v_; },
    setCreatePnrParamsEncoded:         (s_, v_) => { s_.createPnrParamsEncoded          = v_; },
    setDepartureCache:                 (s_, v_) => { s_.departureCache                  = v_; },
    setDepartureCalendarLabel:         (s_, v_) => { s_.departureCalendarLabel          = v_; },
    setDetailsSidebarOpen:             (s_, v_) => { s_.isDetailsSidebarOpen            = v_; },
    setDevice:                         (s_, v_) => { s_.device                          = v_; },
    setDisplayExpiredFlightModal:      (s_, v_) => { s_.displayExpiredFlightModal       = v_; },
    setExpiry:                         (s_, v_) => { s_.expiry                          = v_; },
    setExpiryTimeForMessage:           (s_, v_) => { s_.expiryTimeForMessage            = v_; },
    setFieldsToValidate:               (s_, v_) => { s_.fieldsToValidate                = v_; },
    setFlightBookData:                 (s_, v_) => { s_.flightBookData                  = v_; },
    setFlightDetails:                  (s_, v_) => { s_.flightDetails                   = v_; },
    setFlightResults:                  (s_, v_) => { s_.flightResults                   = v_; },
    setFlightResultsHeader:            (s_, v_) => { s_.flightResultsHeader             = v_; },
    setHomeRouterLinkUrl:              (s_, v_) => { s_.mainData.header.menu[0].url     = v_; },
    setIsDetailsModalOpen:             (s_, v_) => { s_.isDetailsModalOpen              = v_; },
    setIsDetailsSidebarOpen:           (s_, v_) => { s_.isDetailsSidebarOpen            = v_; },
    setIsMobileFlightSearchOpen:       (s_, v_) => { s_.isMobileFlightSearchOpen        = v_; },
    setLanguage:                       (s_, v_) => { s_.language                        = v_; },
    setLoader:                         (s_, v_) => { s_.loader                          = v_; },
    setLoaderArrival:                  (s_, v_) => { s_.loader.flightData.arrival       = v_; },
    setLoaderDeparture:                (s_, v_) => { s_.loader.flightData.departure     = v_; },
    setLoading:                        (s_, v_) => { s_.loading                         = v_; },
    setLoadingHeadline:                (s_, v_) => { s_.loadingHeadline                 = v_; },
    setLoadingSubtitle:                (s_, v_) => { s_.loadingSubtitle                 = v_; },
    setMainData:                       (s_, v_) => { s_.mainData                        = v_; },
    setOneWayFromStore:                (s_, v_) => { s_.oneWayFromStore                 = v_; },
    setPageLabels:                     (s_, v_) => { s_.pageLabels                      = v_; },
    setPassengerCountObject:           (s_, v_) => { s_.passengerCountObject            = v_; },
    setPaymentData:                    (s_, v_) => { s_.paymentData                     = v_; },
    setPaymentParamsEncoded:           (s_, v_) => { s_.paymentParamsEncoded            = v_; },
    setPnrData:                        (s_, v_) => { s_.pnrData                         = v_; },
    setReservationProps:               (s_, v_) => { s_.setReservationProps             = v_; },
    setReturnCache:                    (s_, v_) => { s_.returnCache                     = v_; },
    setReturnCalendarLabel:            (s_, v_) => { s_.returnCalendarLabel             = v_; },
    setSearchSessionlessParamsEncoded: (s_, v_) => { s_.searchSessionlessParamsEncoded  = v_; },
    setSession:                        (s_, v_) => { s_.session                         = v_; },
    setSessionDate:                    (s_, v_) => { s_.sessionDate                     = v_; },
    setShouldGetLoaderDataOnMounted:   (s_, v_) => { s_.shouldGetLoaderDataOnMounted    = v_; },
    setMetaTagsSet:                    (s_, v_) => { s_.metaTagsSet                     = v_; },
    setContactData:                    (s_, v_) => { s_.contactData                     = v_; }
  },
  actions: {
    setIsExpiryBeingWatched           (ctx_, v_) { ctx_.commit('setIsExpiryBeingWatched'            , v_); },
    setAllFlightSearchImagesLoaded    (ctx_, v_) { ctx_.commit('setAllFlightSearchImagesLoaded'     , v_); },
    incrementNewSearches              (ctx_, v_) { ctx_.commit('incrementNewSearches'               , v_); },
    loading                           (ctx_, v_) { ctx_.commit('setLoading'                         , v_); },
    setBookParamsEncoded              (ctx_, v_) { ctx_.commit('setBookParamsEncoded'               , v_); },
    setBookSession                    (ctx_, v_) { ctx_.commit('setBookSession'                     , v_); },
    setPaymentSession                 (ctx_, v_) { ctx_.commit('setPaymentSession'                  , v_); },
    setBookToPay                      (ctx_, v_) { ctx_.commit('setBookToPay'                       , v_); },
    setComingFromSearchToBook         (ctx_, v_) { ctx_.commit('setComingFromSearchToBook'          , v_); },
    setCreatePnrParamsEncoded         (ctx_, v_) { ctx_.commit('setCreatePnrParamsEncoded'          , v_); },
    setDepartureCache                 (ctx_, v_) { ctx_.commit('setDepartureCache'                  , v_); },
    setDepartureCalendarLabel         (ctx_, v_) { ctx_.commit('setDepartureCalendarLabel'          , v_); },
    setDetailsSidebarOpen             (ctx_, v_) { ctx_.commit('setDetailsSidebarOpen'              , v_); },
    setDevice                         (ctx_, v_) { ctx_.commit('setDevice'                          , v_); },
    setDisplayExpiredFlightModal      (ctx_, v_) { ctx_.commit('setDisplayExpiredFlightModal'       , v_); },
    setExpiry                         (ctx_, v_) { ctx_.commit('setExpiry'                          , v_); },
    setExpiryTimeForMessage           (ctx_, v_) { ctx_.commit('setExpiryTimeForMessage'            , v_); },
    setFieldsToValidate               (ctx_, v_) { ctx_.commit('setFieldsToValidate'                , v_); },
    setFlightBookData                 (ctx_, v_) { ctx_.commit('setFlightBookData'                  , v_); },
    setFlightDetails                  (ctx_, v_) { ctx_.commit('setFlightDetails'                   , v_); },
    setFlightResults                  (ctx_, v_) { ctx_.commit('setFlightResults'                   , v_); },
    setFlightResultsHeader            (ctx_, v_) { ctx_.commit('setFlightResultsHeader'             , v_); },
    setHomeRouterLinkUrl              (ctx_, v_) { ctx_.commit('setHomeRouterLinkUrl'               , v_); },
    setIsDetailsModalOpen             (ctx_, v_) { ctx_.commit('setIsDetailsModalOpen'              , v_); },
    setIsDetailsSidebarOpen           (ctx_, v_) { ctx_.commit('setIsDetailsSidebarOpen'            , v_); },
    setIsMobileFlightSearchOpen       (ctx_, v_) { ctx_.commit('setIsMobileFlightSearchOpen'        , v_); },
    setLanguage                       (ctx_, v_) { ctx_.commit('setLanguage'                        , v_); },
    setLoader                         (ctx_, v_) { ctx_.commit('setLoader'                          , v_); },
    setLoaderArrival                  (ctx_, v_) { ctx_.commit('setLoaderArrival'                   , v_); },
    setLoaderDeparture                (ctx_, v_) { ctx_.commit('setLoaderDeparture'                 , v_); },
    setLoading                        (ctx_, v_) { ctx_.commit('setLoading'                         , v_); },
    setLoadingHeadline                (ctx_, v_) { ctx_.commit('setLoadingHeadline'                 , v_); },
    setLoadingSubtitle                (ctx_, v_) { ctx_.commit('setLoadingSubtitle'                 , v_); },
    setMainData                       (ctx_, v_) { ctx_.commit('setMainData'                        , v_); },
    setOneWayFromStore                (ctx_, v_) { ctx_.commit('setOneWayFromStore'                 , v_); },
    setPageLabels                     (ctx_, v_) { ctx_.commit('setPageLabels'                      , v_); },
    setPassengerCountObject           (ctx_, v_) { ctx_.commit('setPassengerCountObject'            , v_); },
    setPaymentData                    (ctx_, v_) { ctx_.commit('setPaymentData'                     , v_); },
    setPaymentParamsEncoded           (ctx_, v_) { ctx_.commit('setPaymentParamsEncoded'            , v_); },
    setPnrData                        (ctx_, v_) { ctx_.commit('setPnrData'                         , v_); },
    setReservationProps               (ctx_, v_) { ctx_.commit('setReservationProps'                , v_); },
    setReturnCache                    (ctx_, v_) { ctx_.commit('setReturnCache'                     , v_); },
    setReturnCalendarLabel            (ctx_, v_) { ctx_.commit('setReturnCalendarLabel'             , v_); },
    setSearchSessionlessParamsEncoded (ctx_, v_) { ctx_.commit('setSearchSessionlessParamsEncoded'  , v_); },
    setSession                        (ctx_, v_) { ctx_.commit('setSession'                         , v_); },
    setSessionDate                    (ctx_, v_) { ctx_.commit('setSessionDate'                     , v_); },
    setShouldGetLoaderDataOnMounted   (ctx_, v_) { ctx_.commit('setShouldGetLoaderDataOnMounted'    , v_); },
    setMetaTagsSet                    (ctx_, v_) { ctx_.commit('setMetaTagsSet'                     , v_); },
    setContactData                    (ctx_, v_) { ctx_.commit('setContactData'                     , v_); }
  },
  modules: { jv: jsonapiModule(jsonapiVuex, jvConfig) }
});
/* eslint-enable */
