import { ref, reactive, computed, watch } from 'vue';
import { required, email, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
// import RecaptchaComponent from '../../components/RecaptchaComponent.vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'contact-page',
  components: { VueRecaptcha },
  props: [],
  setup() {
    const store = useStore();
    const toast = useToast(); toast.removeGroup('headless');

    const language = ref(store.getters.language);

    const fieldsToValidate = reactive({
      name: ref(''),
      email: ref(''),
      message: ref('')
    });

    const resetForm = () => {
      fieldsToValidate.name = '';
      fieldsToValidate.email = '';
      fieldsToValidate.message = '';
      submitted.value = false;
    };

    const loading = ref(true);
    const isLoading = computed(() => loading.value);
    const contactData = computed(() => store.getters.contactData);

    watch(() => contactData.value, (newValue) => {
      addressIcon.value = newValue.content.info.address.icon.url;
    }, { deep: true });

    const addressIcon = ref();

    let rules;
    const v$ = ref(null);

    fetch(
      `https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/contact`
    )
      .then((res) => res.json())
      .then((d) => {
        // console.log('setting contact data', d.data);
        store.dispatch('setContactData', d.data);
        // contactData.value = Object.assign({}, d.data);
        const c_ = contactData.value.content;
        rules = {
          name: {
            required: helpers.withMessage(c_.pageLabels.form.regexError.required, required),
            regex: helpers.withMessage(c_.pageLabels.form.regexError[c_.form.fields.name.regexError],
              helpers.regex(new RegExp(c_.form.fields.name.regex)))
          },
          email: {
            required: helpers.withMessage(c_.pageLabels.form.regexError.required, required),
            // email,
            email: helpers.withMessage(c_.pageLabels.form.regexError[c_.form.fields.email.regexError], email),
            regex: helpers.withMessage(c_.pageLabels.form.regexError[c_.form.fields.email.regexError],
              helpers.regex(new RegExp(c_.form.fields.email.regex)))
          },
          message: {
            required: helpers.withMessage(c_.pageLabels.form.regexError.required, required)
          }
        };
        v$.value = useVuelidate(rules, fieldsToValidate);
        setTimeout(() => {
          loading.value = false;
        }, 200);
      })
      .catch((err) => {
        console.error(err);
        return [];
      });

    const submitted = ref(false);

    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid) {
        setTimeout(() => {
          submitted.value = true;
        }, 1000);
        return;
      }

      var xhr = new XMLHttpRequest();
      xhr.open(
        contactData.value.content.form.method,
        contactData.value.content.form.apiUrl
      );
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          // toast success
          if (xhr.status === 200) {
            const response = JSON.parse(xhr.responseText);
            if (response.data.error) {
              toast.add({ group: 'headless', severity: 'warn', detail: response.data.error.showMessage, life: 5000 });
            } else {
              toast.add({ group: 'mail', severity: 'success', detail: response.data.message, life: 5000 });
            }
          }
        }
      };

      const data = `{"name":"${fieldsToValidate.name}","email":"${fieldsToValidate.email}","message":"${fieldsToValidate.message}"}`;

      xhr.send(data);

      submitted.value = false;
      resetForm();
    };

    const recaptchaRef = ref(null);

    // const handleSubmit = () => {
    //   console.log('handleSubmit');
    //   // recaptchaRef.value refers to the RecaptchaComponent instance
    //   // recaptchaRef.value.render();
    //   // const token = recaptchaRef.value.getToken();
    //   // console.log('reCAPTCHA token:', token);
    //   // Now you can send `token` to your backend...
    // };

    return {
      recaptchaRef,
      language,
      fieldsToValidate,
      v$,
      resetForm,
      handleSubmit,
      submitted,
      isLoading,
      addressIcon,
      contactData
    };
  }
};
