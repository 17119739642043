// Core
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Recaptcha
import { VueRecaptcha } from 'vue-recaptcha';

// PrimeVue
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import AutoComplete from 'primevue/autocomplete';
import OverlayPanel from 'primevue/overlaypanel';
import Card from 'primevue/card';
import TextArea from 'primevue/textarea';
import Sidebar from 'primevue/sidebar';
import Dialog from 'primevue/dialog';
import Paginator from 'primevue/paginator';
import Toast from 'primevue/toast';
import MultiSelect from 'primevue/multiselect';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ToastService from 'primevue/toastservice';
import Breadcrumb from 'primevue/breadcrumb';

// Custom components
import Header from './components/Header/HeaderComponent.vue';
import Footer from './components/Footer/FooterComponent.vue';
import FlightSearch from './components/FlightSearch/FlightSearchComponent.vue';
import MyBreadcrumb from './components/Breadcrumb/BreadcrumbComponent.vue';
import MyCalendar from './components/CalendarComponent.vue';
import MyNumberInput from './components/CustomInputNumber.vue';
import Loader from './components/Loader/LoaderComponent.vue';
import FlightDetailsModalContent from './components/FlightDetailsModalContent/FlightDetailsModalContentComponent.vue';
import DateTextInput from './components/DateTextInput/DateTextInputComponent.vue';
import FormattedCurrency from './components/FormattedCurrency/FormattedCurrencyComponent.vue';

// Custom views
import Home from './views/home/HomeView.vue';

createApp(App)
  .use(ToastService)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .component('Sidebar', Sidebar)
  .component('Accordion', Accordion)
  .component('AccordionTab', AccordionTab)
  .component('Paginator', Paginator)
  .component('Dropdown', Dropdown)
  .component('Button', Button)
  .component('Calendar', Calendar)
  .component('Checkbox', Checkbox)
  .component('RadioButton', RadioButton)
  .component('InputText', InputText)
  .component('InputNumber', InputNumber)
  .component('AutoComplete', AutoComplete)
  .component('OverlayPanel', OverlayPanel)
  .component('Card', Card)
  .component('TextArea', TextArea)
  .component('Dialog', Dialog)
  .component('Toast', Toast)
  .component('MultiSelect', MultiSelect)
  .component('TabView', TabView)
  .component('TabPanel', TabPanel)
  .component('FlightSearch', FlightSearch)
  .component('Breadcrumb', Breadcrumb)
  .component('MyBreadcrumb', MyBreadcrumb)
  .component('Header', Header)
  .component('Footer', Footer)
  .component('Home', Home)
  .component('MyCalendar', MyCalendar)
  .component('MyNumberInput', MyNumberInput)
  .component('Loader', Loader)
  .component('DateTextInput', DateTextInput)
  .component('FlightDetailsModalContent', FlightDetailsModalContent)
  .component('FormattedCurrency', FormattedCurrency)
  .component('Recaptcha', VueRecaptcha)
  .mount('#app');
